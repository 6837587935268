import React, { useCallback, useEffect, useState } from "react";
import { ProgressBar } from "components/Common";
import TabImages from "./TabImages";
import TabBox from "./TabBox";
import ModalAlert from "../../Layouts/Modal/ModalAlert";
import { useAccount, useContractRead } from "wagmi";
import { presaleContractAddress, totalTokens } from "data/consts";
import { preSaleAbiFunctionsName } from "data/abiFunctions";
import preSaleAbi from "data/preSaleAbi.json";
import { intToString } from "utils/numbers";

const BuyCoinHero = () => {
  const [showBuycoinTab, setshowBuycoinTab] = useState("wallet-connect");

  const { isConnected } = useAccount();
  const [pmcSold, setSold] = useState(0);
  const [percentSold, setPercenteSold] = useState(95);
  const [convertedUsdt, setConvertedUsdt] = useState(0);
  const [totalInUsdt, setTotalInUsdt] = useState(0);

  const { data } = useContractRead({
    address: presaleContractAddress,
    abi: preSaleAbi,
    functionName: preSaleAbiFunctionsName.inSale,
  });
  const { data: pmcByUsdt, refetch } = useContractRead({
    address: presaleContractAddress,
    abi: preSaleAbi,
    functionName: preSaleAbiFunctionsName.usdtBuyHelper,
    args: [0],
  });
  const { data: totalUsdt } = useContractRead({
    address: presaleContractAddress,
    abi: preSaleAbi,
    functionName: preSaleAbiFunctionsName.usdtBuyHelper,
    args: [totalTokens],
  });
  useEffect(() => {
    try {
      setConvertedUsdt((pmcByUsdt?.toNumber() || 0) / Math.pow(10, 6));
      console.log(totalUsdt.toNumber());
      setTotalInUsdt((totalUsdt?.toNumber() || 0) / Math.pow(10, 6));
    } catch {}
  }, [pmcByUsdt, totalUsdt]);

  useEffect(() => {
    if (data) {
      const div = totalTokens - data.toNumber();
      setSold(div);
      refetch({
        address: presaleContractAddress,
        abi: preSaleAbi,
        functionName: preSaleAbiFunctionsName.usdtBuyHelper,
        args: [data.toNumber()],
      });
    }
  }, [data]);

  useEffect(() => {
    if (isConnected) setshowBuycoinTab("wallet-buy");
    else setshowBuycoinTab("wallet-connect");
  }, [isConnected]);
  return (
    <>
      <div className="buycoin-hero-section">
        <div className="container">
          <div className="section-inner">
            <div className="buycoin-box active">
              <div className="coin-content">
                <div className="row align-items-center">
                  <div className="col-lg-6 order-lg-1 order-md-2 order-2">
                    <TabImages
                      isConnected={isConnected}
                      showBuycoinTab={showBuycoinTab}
                    />
                    <ProgressBar
                      title1={`Sold/Total token ($) : ${intToString(
                        convertedUsdt
                      )} / ${intToString(totalInUsdt)} `}
                      title2={`Sold/Total token (PMC) : ${intToString(
                        pmcSold
                      )} / ${intToString(totalTokens)} `}
                      percentage={percentSold}
                      // percentage={50}
                      min="0"
                      max="100"
                    />
                  </div>
                  <div className="col-lg-6 order-lg-2 order-md-1 order-1">
                    <TabBox
                      isConnected={isConnected}
                      showBuycoinTab={showBuycoinTab}
                      setshowBuycoinTab={setshowBuycoinTab}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text text-center mt-4">
            <a href="#how-to-buy" className="text-white">
              HOW TO BUY? <i className="fi fi-rr-angle-circle-down ml-5"></i>
            </a>
          </div>
        </div>
      </div>

      {/* { warning, error, success } */}
      {/* { fi-rr-exclamation, fi-rr-close, fi-rr-check } */}
      <ModalAlert
        title="success"
        icon="fi-rr-check"
        status="success"
        setShowModal={() => {}}
        showModal={false}
        buttonAcceptName="Ok!"
      >
        {
          <p>
            compound connected comfortable visit mainly generally native moment
            dull except direct equally
          </p>
        }
      </ModalAlert>

      {/* Moadl Loading */}
    </>
  );
};

export default BuyCoinHero;

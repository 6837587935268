import React, { useEffect, useState } from "react";
import { ReferralCodeInput } from "components/Common";
import { CoinInput } from "components/Common/CoinInput";

import ethIcon from "assets/images/icons/ethereum.svg";
import pmcIcon from "assets/images/icons/pmc.png";
import {
  useAccount,
  useContractEvent,
  useContractRead,
  useSigner,
} from "wagmi";
import { presaleContractAddress } from "data/consts";
import { preSaleAbiFunctionsName } from "data/abiFunctions";
import presaleABI from "data/preSaleAbi.json";
import { handleBuyEth } from "utils/smartcontractHelper";
import { getBuyWithEth } from "utils/calculatePrice";
import { utils } from "ethers";
import Loading from "components/Common/Loading";
import { useEthBalance } from "hooks/useEthBalance";
import { handleWeb3Err } from "utils/web3err";

const WalletETH = ({ setshowBuycoinTab }) => {
  const { address } = useAccount();
  const [ethAmount, setEthAmount] = useState(0);
  const [pmcAmount, setPMCAmount] = useState(0);
  const ethBalance = useEthBalance(address);

  const [loadingForm, setLoadingFormData] = useState({
    show: false,
    msg: "",
    icon: "",
  });
  const [err, setErr] = useState({
    err: false,
    msg: "",
  });
  const { data: signer } = useSigner();

  useContractEvent({
    address: presaleContractAddress,
    abi: presaleABI,
    eventName: preSaleAbiFunctionsName.TokensBought,
    listener: (event) => console.log(event),
  });

  const { data, refetch, isError } = useContractRead({
    address: presaleContractAddress,
    abi: presaleABI,
    functionName: preSaleAbiFunctionsName.ethBuyHelper,
    args: [pmcAmount],
  });
  useEffect(() => {
    if (pmcAmount > 2000) {
      refetch();
    }

    if (data) setEthAmount(utils.formatEther(data));
  }, [pmcAmount, data]);

  const handleBuy = () => {
    if (ethBalance < 0.00001) {
      setErr({
        err: true,
        msg: "Oops! It looks like you don't have enough ETH/USDT to pay for that transaction. Please reduce the amount of IMPT and try again. ",
      });
    }
    if (pmcAmount < 10_000_000) {
      setErr({
        err: true,
        msg: "Min: 10M PMC",
      });
      return;
    }
    setErr({
      err: false,
      msg: "",
    });
    setLoadingFormData({
      icon: "loading",
      msg: "Please wait...",
      show: true,
    });
    handleBuyEth(signer, pmcAmount, ethAmount)
      .then((tr) => {
        console.log(`TransactionResponse TX hash: ${tr.hash}`);
        tr.wait().then((receipt) => {
          setPMCAmount(0);
          setLoadingFormData({
            icon: "success",
            msg: "Success",
            show: true,
          });
          setTimeout(() => {
            closeLoading();
          }, 6 * 1000);
        });
      })
      .catch((e) => {
        const err = handleWeb3Err(e);

        setLoadingFormData({
          icon: "error",
          msg: err,
          show: true,
        });
        setTimeout(() => {
          closeLoading();
        }, 6 * 1000);
      });
  };

  const closeLoading = () => {
    setLoadingFormData({ show: false, icon: "loading", err: "" });
  };
  return (
    <>
      <div className="form-content">
        <button
          type="button"
          className="btn-back"
          onClick={() => setshowBuycoinTab("wallet-buy")}
        >
          <i className="fi fi-rr-arrow-left"></i>Back
        </button>
        <h6 className="title text-center mb-4">Buy With ETH</h6>
        <CoinInput
          title="Planet Memes Token"
          coinIcon={pmcIcon}
          coinName="PMC"
          onChange={(e) => setPMCAmount(e.target.value)}
          value={pmcAmount}
          showMsg={err.err}
          msgStatus="error"
          msgText={err.msg}
          inputReadOnly={false}
        />
        <CoinInput
          title="Eth Amount"
          coinIcon={ethIcon}
          coinName="ETH"
          showMsg={false}
          value={ethAmount}
          msgStatus=""
          msgText=""
          inputReadOnly={true}
        />
        {/* <ReferralCodeInput
        title="Referral Code"
        placeholder="write here..."
        showMsg={false}
        msgStatus="error"
        msgText="thumb chose around fifth progress industry floating consonant slabs cowboy snow many"
      /> */}
        <div className="buttons-group mb-0 mt-4">
          <button
            type="button"
            // disabled={!write}
            className="btn btn-primary"
            onClick={() => handleBuy()}
          >
            Buy
          </button>
        </div>
      </div>
      <Loading
        title={loadingForm.msg}
        state={loadingForm.icon}
        showModal={loadingForm.show}
        closeModal={() => closeLoading()}
      />
    </>
  );
};

export default WalletETH;

export const FaqData = [
    {
        id: 1,
        title: 'Where do I get my referral link?',
        desc: 'Connect a wallet and find your referral link in the Referral section.'
    },
    {
        id: 2,
        title: 'How much crypto can I earn via the Swap Referral Program?',
        desc: 'You can earn from 10% to 20% in BSW right after your friends have made a swap. The percentage depends on the amount of staked BSW tokens in BSW Holder Pool:\n 0 BSW Staked = 10% Reff Bonus\n 200 BSW Staked = 12% Reff Bonus \n1 000 BSW Staked = 14% Reff Bonus \n3 000 BSW Staked = 16% Reff Bonus \n7 000 BSW Staked = 18% Reff Bonus \n10 000 BSW Staked = 20% Reff Bonus '
    },
    {
        id: 3,
        title: 'How do I invite a referral friend?',
        desc: 'Invite your friends to register via your referral link.'
    },
    {
        id: 4,
        title: 'What percentage of Swap referral rewards will I earn if I have 0 BSW staked in BSW Holder Pool?',
        desc: 'If you have 0 BSW staked in the BSW Holder pool, you will be getting 10% by default. To earn more in the Swap Referral Program on Biswap, you need to stake BSW in the Holder Pool.'
    },
    {
        id: 5,
        title: 'Are there separate balances for referral rewards from friends Swaps, Farms, Launchpools?',
        desc: 'Yes, there are three separate balances for the referral rewards.'
    },
    {
        id: 6,
        title: 'Is the Swap referral program active for all swap pairs?',
        desc: 'No. Referral Program consider only whitelisted pairs, including but not limited to:ETH - BTCB, BUSD - USDT, BTCB - USDT, ETH - USDT, USDC - USDT, BNB - BSW, ETH - BNB, BNB - USDT, BNB - BUSD, BNB - BTCB, USDT - BSW, LINK - BNB, ADA - BNB, DOGE - BNB, CAKE - BNB, UST - BUSD, DOT - BNB, DAI - USDT, UNI - BNB, FIL - USDT, USDT - LTC, BUSD - VAI, SOL - BNB, BUSD - TUSD, BFG - BSW, XVS - BNB, AVAX - BNB. Find the complete list of whitelisted pairs in Biswap Docs'
    },
    {
        id: 7,
        title: 'How do I generate a new referral link?',
        desc: 'Find "My Referral Link" block and click on the "plus" button near the link field. Choose the profit share for your friends and click generate a referral link.'
    },
    {
        id: 8,
        title: 'How much can I earn from my friends Farms & Launchpools ? ',
        desc: 'You can expect a 5% return from your friends earnings in BSW.'
    },
    {
        id: 9,
        title: 'How does profit sharing work?',
        desc: 'Profit sharing allows you to share a portion of referral rewards with your invited friends. The percentage can be: 0%, 10% 25%, 50%'
    },
    {
        id: 10,
        title: 'Where are all my generated referral links?',
        desc: 'View all of your generated links on the "Referral Links" section of the Referral page.'
    },
    {
        id: 11,
        title: 'In what crypto currency the referral commission is accounted to my referral balance?',
        desc: 'The referral rewards are accounted in BSW tokens only.'
    },
    {
        id: 12,
        title: 'Are there fees for referral rewards withdrawal from referral balances?',
        desc: 'Once you withdraw from your referral balances, a BSC network fee of approximately 0.5 BSW will be charged.'
    },
    {
        id: 13,
        title: 'Is Referral Program Active for all Launchpools?',
        desc: 'No. Referral Program is active only for Stake BSW - Earn BSW Launchpool without auto-compound.'
    },
    {
        id: 14,
        title: 'When will I get my referral reward from Farms & Launchpools?',
        desc: 'You will get your referral reward the moment your friend makes Harvest.'
    },
    {
        id: 15,
        title: 'Can I profit from the Referral Program without any investments from my side?',
        desc: 'Yes, you can earn 10% from the Swap Referral Program and 5% from Farms & Launchpools without any required investments from your side.'
    }
]
import React from "react";

const ReferralCode = () => {
  return (
    <div className="referral-code">
      <h2 className="wallet-title">Refer Your Friends</h2>
      <div className="code-inner">
        <div className="gift-image">
          <video
            src={"/static/videos/roadmap-1.webm"}
            autoPlay
            muted
            loop
          ></video>
        </div>
        <div className="code-info">
          <p className="desc">
            Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam
            Nonumy Eirmod Tempor
          </p>
          <div className="code">
            <h6>abcdfg123</h6>
            <button type="button" className="btn-copy">
              <i className="fi fi-rr-copy-alt"></i>
            </button>
            <button type="button" className="btn-setting">
              <i className="fi fi-rr-settings"></i>
            </button>
          </div>
          <div className="setting"></div>
        </div>
        <div className="clam">
          <div className="money">
            <h2>$ 150</h2>
          </div>
          <button type="button" className="btn btn-primary">
            Clam
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReferralCode;

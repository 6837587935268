import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "Styles/styles.scss";
import "assets/css/uicons-regular-rounded.css";
import "assets/css/uicons-solid-rounded.css";
import "assets/css/uicons-brands.css";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { SENTRY_DSN } from "utils/env";

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new BrowserTracing()],
  /* 
     We recommend adjusting this value in production, or using tracesSampler for finer control
     (Lower the value in production)
     While testing, it is okay to keep the tracesSampleRate as 1.0. 
     This means that every action performed in the browser will be sent as a transaction to Sentry. 
     In production, this value should be lowered to collect a uniform sample data size without reaching Sentry’s transaction quota.
     Alternatively, to collect sample data dynamically, tracesSampler can be used to filter these transactions.
     for more info visit => https://docs.sentry.io/platforms/javascript/guides/react/configuration/sampling/
  */
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

import React, { useState } from 'react'
import { FaqData } from 'Mock/FaqData'
import CardFaq from 'components/Layouts/Cards/CardFaq';

const Faqs = () => {

    const [showFaq, setShowFaq] = useState('');

    var oddData = [], evenData = [];
    FaqData.forEach((faqitem, i) => {
        if (i % 2 === 0) {
            oddData.push(faqitem)
        } else {
            evenData.push(faqitem)
        }
    });

    return (
        <div className='faqs-section'>
            <div className="container">
                <div className="page-title">
                    <div className="title-inner">
                        <h2>faqs</h2>
                    </div>
                </div>
                <div className="section-inner">
                    <div className="accordion">
                        <div className="row">
                            {
                                <>
                                    <div className="col-lg-6">
                                        {
                                            oddData.map(item => (
                                                <CardFaq
                                                    key={item.id}
                                                    id={item.id}
                                                    title={item.title}
                                                    desc={item.desc}
                                                    showFaq={showFaq}
                                                    setShowFaq={setShowFaq}
                                                />
                                            ))
                                        }
                                    </div>
                                    <div className="col-lg-6">
                                        {
                                            evenData.map(item => (
                                                <CardFaq
                                                    key={item.id}
                                                    id={item.id}
                                                    title={item.title}
                                                    desc={item.desc}
                                                    showFaq={showFaq}
                                                    setShowFaq={setShowFaq}
                                                />
                                            ))
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faqs
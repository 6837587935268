import React, { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

import { Autoplay, Navigation, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";

import screen1 from "assets/images/clash-of-memes/game-bg-1.jpg";
import screen2 from "assets/images/clash-of-memes/game-bg-2.jpg";
import screen3 from "assets/images/clash-of-memes/game-bg-3.jpg";
import screen4 from "assets/images/clash-of-memes/game-bg-4.jpg";
import screen5 from "assets/images/clash-of-memes/game-bg-5.jpg";

// Other Game
import game_1 from "assets/images/game-covers/pepe-wallpaper-1.jpg";
import game_2 from "assets/images/game-covers/memecoin-wallpaper-1.jpg";

const dataSlider = [
  { id: 1, title: "game background 1", imgUrl: screen1 },
  { id: 2, title: "game background 2", imgUrl: screen2 },
  { id: 3, title: "game background 3", imgUrl: screen3 },
  { id: 4, title: "game background 4", imgUrl: screen4 },
  { id: 5, title: "game background 5", imgUrl: screen5 },
];

const Games = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <div className="games-section">
        <div className="shape-up"></div>
        <div className="container">
          <div className="page-title">
            <div className="title-inner">
              <h2>
                The <span>games</span> we made
              </h2>
            </div>
          </div>
          <div className="section-inner">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="game-box" data-aos="zoom-out">
                  <Swiper
                    className="swiper-games"
                    spaceBetween={5}
                    slidesPerView={1}
                    effect={"fade"}
                    navigation={true}
                    modules={[Autoplay, Navigation, EffectFade]}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                  >
                    {dataSlider.map((slide) => (
                      <SwiperSlide key={slide.id}>
                        <div className="slide-content">
                          <div className="img-content">
                            <img
                              loading="lazy"
                              src={slide.imgUrl}
                              alt={slide.title}
                            />
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className="text-overlay">
                    <div className="text-inner">
                      <h3>Clash of memes</h3>
                      {/* <a href='#' className='btn btn-link'>Visit Website<i className='fi fi-rr-arrow-small-right'></i></a> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="game-box" data-aos="fade-right">
                  <div className="image-content">
                    <img src={game_1} alt="game-name" />
                  </div>
                  <div className="text-overlay small">
                    <div className="text-inner">
                      <h3>Pepe Match</h3>
                      {/* <a href='#' className='btn btn-link'>Visit Website<i className='fi fi-rr-arrow-small-right'></i></a> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="game-box disabled" data-aos="fade-left">
                  <div className="image-content">
                    <img src={game_2} alt="game-name" />
                  </div>
                  <div className="text-overlay small">
                    <div className="text-inner">
                      <h3>Meme Coin Menia</h3>
                      <h6>Coming Soon</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-down"></div>
      </div>
    </>
  );
};

export default Games;

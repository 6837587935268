import React from "react";
export const ConnectButton = ({ title, img, id, disabled, key, onClick }) => {
  return (
    <div className="card-wallet-item" aria-disabled={disabled}>
      <div className="card-inner" onClick={onClick}>
        <div className="thumb">
          <img loading="lazy" src={img} alt="" />
        </div>
        <h6 className="wallet-name">{title}</h6>
      </div>
    </div>
  );
};

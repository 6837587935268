import React, { useState } from 'react'

const Description = () => {

    const [showMore, setshowMore] = useState(false)
    return (
        <>
            <div className="portfolio-description">
                <div className="container">
                    <div className="section-inner">
                        <div className={`desc ${showMore && 'active'}`}>
                            <p>Cubistas is a groundbreaking art collection that challenges the boundaries of creativity and questions the essence of artistic origin. The collection is an amalgamation of artificially generated Cubist pieces, where cutting-edge AI technology has been harnessed to conceive and curate an entirely new body of work that pays homage to the pioneers of Cubism.</p>
                            <p>The project is designed to shine a light on the revolutionary art movement that began in the early 20th century, famously heralded by artists such as Pablo Picasso and Georges Braque. Cubism's radical approach to visual perception—depicting subjects from a multitude of viewpoints—transformed the art world, a disruption that resonates well with the disruption AI brings to society today.</p>
                        </div>
                        <button className='btn-see-more' onClick={() => setshowMore(!showMore)}>
                            {showMore ? 'Show less' : 'Show more'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Description
import React from 'react'
import Headers from 'components/Layouts/Header'
import Footer from 'components/Layouts/Footer'
import PageHero from 'components/Layouts/Heros/PageHero'
import WalletInfo from './WalletInfo'

const Referral = () => {
    return (
        <>
            <Headers haveBg={true} />
            <PageHero title="Referral" />
            <WalletInfo />
            <Footer />
        </>
    )
}

export default Referral
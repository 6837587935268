import React, { useEffect, useState } from "react";
import { generateLinkBuyEth } from "utils/rampHelper";
import {
  useAccount,
  useBalance,
  useConnect,
  useContractRead,
  useDisconnect,
} from "wagmi";
import { ethers } from "ethers";
import presaleABI from "../../../data/preSaleAbi.json";
import { presaleContractAddress } from "data/consts";
import { preSaleAbiFunctionsName } from "data/abiFunctions";

const WalletBuy = ({ setshowBuycoinTab }) => {
  const { disconnect } = useDisconnect();
  const { address } = useAccount();

  const [usdBalance, setUsdBalance] = useState(0);
  const [pmcBalance, setPMCBalance] = useState(0);

  const balanceUser = useContractRead({
    address: presaleContractAddress,
    abi: presaleABI,
    functionName: preSaleAbiFunctionsName.userDeposits,
    args: [address || "0x"],
  });
  const priceData = useContractRead({
    address: presaleContractAddress,
    abi: presaleABI,
    functionName: preSaleAbiFunctionsName.salePrice,
  });
  const handleBuyWithCard = () => {
    const walletAddress = address;

    window.open(
      generateLinkBuyEth(walletAddress),
      "_blank",
      "noopener,noreferrer"
    );
  };
  useEffect(() => {
    if (priceData?.data && balanceUser?.data) {
      const price = ethers.utils.formatEther(priceData?.data || 0);
      const convertedBalance = ethers.utils.formatEther(balanceUser?.data);
      setPMCBalance(convertedBalance);
      setUsdBalance(price * convertedBalance);
    }
  }, [priceData, balanceUser]);

  return (
    <div className="form-content">
      {/* <button
        type="button"
        className="btn-back"
        onClick={() => setshowBuycoinTab("wallet-choose")}
      >
        <i className="fi fi-rr-arrow-left"></i>Back
      </button> */}
      <h6 className="title text-center mb-4">Buy PMCcoin</h6>
      <div className="coin-remaining">
        <h4 style={{ color: "#fff" }}>
          {pmcBalance} PMC = {usdBalance} $
        </h4>
        <p className="fw-bolder" style={{ color: "#fff" }}>
          Wallet Address
        </p>
        <p style={{ color: "#fff" }}>{address}</p>
      </div>
      <div className="buttons-group mb-0">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setshowBuycoinTab("wallet-ETH")}
        >
          With ETH
        </button>
        <button
          type="button"
          className="btn btn-primary color-2"
          onClick={() => setshowBuycoinTab("wallet-USDT")}
        >
          With USDT
        </button>
        {/* <button type='button' className='btn btn-primary color-3'>With Shiba</button> */}
        <button
          type="button"
          onClick={handleBuyWithCard}
          className="btn btn-primary"
        >
          With Card
        </button>
        <button
          type="button"
          onClick={disconnect}
          className="btn btn-primary-outline"
        >
          Disconnect
        </button>
      </div>
    </div>
  );
};

export default WalletBuy;

export const presaleContractAddress =
  "0xB446E8F9813958F5459dd21684222a0cdeD2f35c";
// export const presaleContractAddressGoerli =
//   "0x5455168D902584c31F14509700AFcdD4d8F1a2c6";

// export const usdtTokenAddressGoerli =
//   "0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C";

export const usdtTokenAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
export const totalTokens = 4_100_000_000;

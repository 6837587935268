import React, { useCallback, useEffect, useMemo } from "react";

import metaMask from "assets/images/buycoin/icon-metamask.svg";
import walletConnect from "assets/images/buycoin/icon-other-wallet.svg";
import { useAccount, useConnect } from "wagmi";
import { ConnectButton } from "./ConnectButton";

const WalletChoose = ({ setshowBuycoinTab }) => {
  const { connect, connectors, isLoading, status, data } = useConnect({
    onError: (e) => console.log(e),
  });

  useEffect(() => {
    console.log(data);
  }, [data]);
  return (
    <div key={1} className="form-content" aria-disabled={isLoading}>
      <button
        type="button"
        className="btn-back"
        onClick={() => setshowBuycoinTab("wallet-connect")}
      >
        <i className="fi fi-rr-arrow-left"></i>Back
      </button>
      <h6 className="title text-center mb-4">Select Provider</h6>
      {connectors.map((connector, i) => (
        <ConnectButton
          title={connector.name}
          disabled={!connector.ready}
          onClick={() => connect({ connector })}
          img={connector.id === "metaMask" ? metaMask : walletConnect}
        />
      ))}
    </div>
  );
};

export default WalletChoose;

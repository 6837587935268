import React from 'react'
import { linksData } from './NavData'


const DrawerLinks = () => {
    return (
        <>
            <ul className='menu-links'>
                {
                    linksData.map(link => (
                        <li className={`link`} key={link.id}>
                            <a href={link.url}>
                                {link.title}
                                {link.haveChild && <span className='icon'><i className='fi fi-rr-caret-right'></i></span>}
                            </a>
                            {link.haveChild ? (
                                <ul className='sub-menu-links active'>
                                    {
                                        link.subLinks.map((sublink, index) => (
                                            <li className='link' key={index}><a href={sublink.url}>{sublink.title}</a></li>
                                        ))
                                    }
                                </ul>
                            ) : null
                            }
                        </li>
                    ))
                }
            </ul>
        </>
    )
}

export default DrawerLinks
export const linksData = [
  {
    id: 1,
    title: "Home",
    url: "/",
    externallink: false,
    tragetBlank: false,
    haveChild: false,
  },
  {
    id: 2,
    title: "DAO",
    url: "https://governance.planetmemes.com",
    externallink: true,
    tragetBlank: true,
    haveChild: false,
  },
  {
    id: 3,
    title: "Partnerships",
    url: "/#partnerships",
    externallink: false,
    tragetBlank: false,
    haveChild: false,
  },
  {
    id: 4,
    title: "Ecosystem",
    url: "/#ecosystem",
    externallink: false,
    tragetBlank: false,
    haveChild: true,
    subLinks: [
      {
        title: "Clash of memes",
        url: "/#clashofmemes",
      },
      // {
      //   title: "Market Place",
      //   url: "http://mp.planetmemes.com",
      //   externallink: true,
      // },
      {
        title: "Games",
        url: "/games_list",
      },
      // {
      //   title: "Pmc testnet",
      //   url: "/#",
      // },
      // {
      //   title: "Bridge",
      //   url: "https://bridge.planetmemes.com",
      //   externallink: true,
      //   tragetBlank: true,
      //   haveChild: false,
      // },
      {
        title: "Memes wallet",
        url: "https://play.google.com/store/apps/details?id=com.memeswallet",
        externallink: true,
        tragetBlank: true,
        haveChild: false,
      },
      // {
      //   title: "Academy",
      //   url: "https://academy.planetmemes.com",
      //   externallink: true,
      //   tragetBlank: true,
      //   haveChild: false,
      // },
      // {
      //   title: "Faucet",
      //   url: "/#",
      // },
    ],
  },
  // {
  //   id: 5,
  //   title: "Pitch Deck",
  //   url: "https://drive.google.com/file/d/18VDibwgL44s-RQEInqdCso_2Vm7ENFnQ/view",
  //   externallink: true,
  //   tragetBlank: true,
  //   haveChild: false,
  // },

  // { id: 5, title: "About us", url: "#" },
  // { id: 2, title: "Memecahin", url: "https://pmcscan.com", tragetBlank: true },
];

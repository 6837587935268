export default function TabPane({ showTab, tabName, children }) {
    return (
        <>
            <div className={`tab-pane fade ${showTab === `${tabName}` ? "show active" : ""}`}>
                <div className="tab-inner">
                    {children}
                </div>
            </div>
        </>
    )
}

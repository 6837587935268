import React, { useEffect } from "react";
import { Button } from "components/Common";

import AOS from "aos";
import "aos/dist/aos.css";

const AboutMarketplace = () => {

  useEffect(() => {
    AOS.init();
    AOS.refresh()
  }, [])

  return (
    <>
      <div className="about-marketplace-section">
        <div className="container">
          <div className="section-inner">
            <div className="grid-text-content" data-aos="fade-right">
              <div className="page-title">
                <div className="title-inner">
                  <h2>
                    About
                    <br />
                    <span>Marketplace</span>
                  </h2>
                </div>
              </div>
              <div className="description">
                <p>
                  Here, you can browse and purchase a wide range of in-game
                  items and skins to enhance your gaming experience. Whether
                  you're looking for a new outfit for your character or a
                  special weapon to use in battle, you're sure to find something
                  that suits your style. With new items being added regularly,
                  there's always something new to discover. So what are you
                  waiting for? Start shopping now and elevate your game!
                </p>
                <div className="btns-group mt-5">
                  <button className="btn btn-secondary icon-right" disabled>
                    Coming Soon
                    <span className="icon"><i className="fi fi-rr-arrow-right"></i></span>
                  </button>
                  {/* <Button
                    type="button"
                    title="Coming Soon"
                    className="btn-secondary"
                    haveHref={true}
                    url=""
                    haveIcon={false}
                    disabled={true}
                  icon="fi-rr-arrow-right"
                  iconPosition="right"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutMarketplace;

import React from "react";

const Button = ({
  type,
  className,
  title,
  haveIcon,
  icon,
  iconPosition,
  haveHref,
  url,
}) => {
  return (
    <>
      {!haveHref ? (
        <button type={type} className={`btn ${className} icon-${iconPosition}`}>
          <span className="text">{title}</span>
          {haveIcon && (
            <span className="icon">
              <i className={`fi ${icon}`}></i>
            </span>
          )}
        </button>
      ) : (
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          className={`btn ${className} icon-${iconPosition}`}
        >
          <span className="text">{title}</span>
          {haveIcon && (
            <span className="icon">
              <i className={`fi ${icon}`}></i>
            </span>
          )}
        </a>
      )}
    </>
  );
};

export { Button };

import React, { useEffect } from "react";
// import { Parallax } from "react-scroll-parallax";
import AOS from "aos";
import "aos/dist/aos.css";
import { Button } from "components/Common";

import imgSrc from "assets/images/hero-index/pepe.png";

const SectionDescription = () => {

  useEffect(() => {
    AOS.init();
    AOS.refresh()
  }, [])


  return (
    <>
      <div className="description-section">
        <div className="container">
          <div className="section-inner">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="text-content" data-aos="fade-up">
                  {/* <Parallax translateY={[25, -25]}> */}
                  <h2>
                    Where Meme Coins Power the Fun of
                    <span> Web3 Gaming! </span>
                  </h2>
                  <div className="desc">
                    <p>
                      Our plug and play games bring the fun of meme coins to
                      the next level, delivering a unique and exciting gaming
                      experience.
                    </p>
                  </div>
                  <ul>
                    <li>
                      <i className="fi fi-rr-gamepad"></i>Play
                    </li>
                    <li>
                      <i className="fi fi-rr-star"></i>Earn
                    </li>
                    <li>
                      <i className="fi fi-rr-grin"></i>Fun
                    </li>
                    <li>
                      <i className="fi fi-sr-arrows-repeat"></i>Repeat
                    </li>
                  </ul>
                  <div className="button-group">
                    <Button
                      title="Whitepaper"
                      haveHref={true}
                      url="https://planet-memes.gitbook.io/planet-memes/"
                      haveIcon={true}
                      icon="fi-rr-arrow-right"
                      iconPosition="right"
                      className="btn-primary"
                    />
                  </div>
                  {/* </Parallax> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="image-content pepe-character">
                  <div className="img-inner">
                    <img loading="lazy" src={imgSrc} alt="doge" />
                  </div>
                  <div className="shapes-grp">
                    <div className="shape shape-1"></div>
                    <div className="shape shape-2"></div>
                    <div className="shape shape-3"></div>
                    <div className="circle"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionDescription;

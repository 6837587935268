import React from "react";

const CardWalletInfo = ({ title, price, comparison, arrow }) => {
  return (
    <div className="card-wallet-info">
      <div className="card-inner">
        <h3 className="c-title">{title}</h3>
        <div className="coin">
          <div className="price">
            <h3>{price}</h3>
            <h6>
              1 <span>PMG</span> = {comparison} <span>btc</span>
            </h6>
          </div>
          <div className={`icon-arrow`}>
            <i className={`fi fi-rr-arrow-${arrow}`}></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardWalletInfo;

import React from "react";

import ShibaUfoimg from "assets/images/referral/shiba-ufo.svg";

const ReferralHero = () => {
  return (
    <>
      <div className="referral-hero-home">
        <div className="container">
          <div className="section-inner">
            <div className="ufo-image-content">
              <div className="img-inner">
                <img loading="lazy" src={ShibaUfoimg} alt="ufo-name" />
              </div>
            </div>
            <div className="text-content">
              <h1>Invite your friends. Earn cryptocurrency together</h1>
              <p>
                Earn up to <strong>20%</strong> from friends’ swap commission on
                Biswap and <strong>5%</strong> from their earnings on Farms &
                Launchpools
              </p>
              <button type="button" className="btn btn-primary">
                Invite Friends
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferralHero;

import React, { useState } from 'react'
import Tabs from 'components/Layouts/Tabs/Tabs';
import TabPane from 'components/Layouts/Tabs/TabPane';
import { Link } from 'react-router-dom';

import nft_img_1 from 'assets/images/nft/1.jpg'
import nft_img_2 from 'assets/images/nft/2.jpg'
import nft_img_3 from 'assets/images/nft/3.jpg'
import nft_img_4 from 'assets/images/nft/4.jpg'
import nft_img_5 from 'assets/images/nft/5.jpg'
import nft_img_6 from 'assets/images/nft/6.jpg'
import nft_img_7 from 'assets/images/nft/7.jpg'
import nft_img_8 from 'assets/images/nft/8.jpg'
import nft_img_9 from 'assets/images/nft/9.jpg'
import { CardNft } from 'components/Layouts/Cards/CardNft';

const dataTabs = [
    { id: 1, title: 'Market', tabName: 'tab-market' },
    { id: 2, title: 'Activity', tabName: 'tab-activity' }
]

const cardData = [
    { id: 1, userName: 'xangel619', inscription: '849187', notforSale: true, btc: '0.15', walletAddress: 'bc1p…j9s6', imgUrl: nft_img_1 },
    { id: 2, userName: 'xangel619', inscription: '433715', notforSale: false, btc: '0.25', walletAddress: 'bc1p…j9s6', imgUrl: nft_img_2 },
    { id: 3, userName: 'xangel619', inscription: '554862', notforSale: false, btc: '0.12', walletAddress: 'bc1p…j9s6', imgUrl: nft_img_3 },
    { id: 4, userName: 'xangel619', inscription: '327203', notforSale: false, btc: '0.12', walletAddress: 'bc1p…j9s6', imgUrl: nft_img_4 },
    { id: 5, userName: 'xangel619', inscription: '629198', notforSale: true, btc: '0.012', walletAddress: 'bc1p…j9s6', imgUrl: nft_img_5 },
    { id: 6, userName: 'xangel619', inscription: '185633', notforSale: true, btc: '0.6', walletAddress: 'bc1p…j9s6', imgUrl: nft_img_6 },
    { id: 7, userName: 'xangel619', inscription: '214350', notforSale: false, btc: '0.74', walletAddress: 'bc1p…j9s6', imgUrl: nft_img_7 },
    { id: 8, userName: 'xangel619', inscription: '819814', notforSale: false, btc: '0.34', walletAddress: 'bc1p…j9s6', imgUrl: nft_img_8 },
    { id: 9, userName: 'xangel619', inscription: '454986', notforSale: true, btc: '0.42', walletAddress: 'bc1p…j9s6', imgUrl: nft_img_9 },
]

const NftMarket = () => {

    const [showTab, setShowTab] = useState("tab-market");

    return (
        <>
            <div className="nft-market-section">
                <div className="container">
                    <div className="section-inner">
                        <Tabs data={dataTabs} showTab={showTab} setShowTab={setShowTab} align="start" />
                        <div className="tab-content">
                            <TabPane showTab={showTab} tabName="tab-market">
                                <div className="grid-layout">
                                    <div className="grid-market">
                                        <div className="cards-list">
                                            {
                                                cardData.map(info => (
                                                    <CardNft
                                                        key={info.id}
                                                        userName={info.userName}
                                                        inscription={info.inscription}
                                                        notforSale={info.notforSale}
                                                        btc={info.btc}
                                                        walletAddress={info.walletAddress}
                                                        imgUrl={info.imgUrl}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="grid-filter">
                                        <div className="grid-inner">
                                            <div className="sort">
                                                <h6><i className='fi fi-rr-sort-amount-down-alt'></i>Sort by:</h6>
                                                <select name="sort" className="form-select">
                                                    <option value="0">Lowest price</option>
                                                    <option value="1">Highest price</option>
                                                    <option value="2">Oldest listing</option>
                                                    <option value="3">Newest listing</option>
                                                    <option value="4">Lowest number</option>
                                                    <option value="5">Highest number</option>
                                                </select>
                                            </div>
                                            <div className="availability">
                                                <h6>Availability</h6>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="radiogroup_availability" id="radiogroup-1" defaultChecked />
                                                    <label className="form-check-label" htmlFor="radiogroup-1">
                                                        All
                                                        <span className="badge bg-secondary">25</span>
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="radiogroup_availability" id="radiogroup-2" />
                                                    <label className="form-check-label" htmlFor="radiogroup-2">
                                                        Listed
                                                        <span className="badge bg-secondary">18</span>
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="radiogroup_availability" id="radiogroup-3" />
                                                    <label className="form-check-label" htmlFor="radiogroup-3">
                                                        Unlisted
                                                        <span className="badge bg-secondary">7</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane showTab={showTab} tabName="tab-activity">
                                <div className="grid-layout">
                                    <div className="grid-market">
                                        <div className="msg">Nothing to show</div>
                                    </div>
                                    <div className="grid-filter">
                                        <div className="grid-inner">
                                            <div className="availability">
                                                <div class="form-check form-switch m-0">
                                                    <input class="form-check-input" type="checkbox" role="switch" id="switch-purchases" />
                                                    <label class="form-check-label" htmlFor="switch-purchases">Purchases Only</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NftMarket
import Footer from "components/Layouts/Footer"
import Headers from "components/Layouts/Header"
import { PortfolioHero } from "./PortfolioHero"
import Description from "./Description"
import NftMarket from "./NftMarket"

const Portfolio = () => {
    return (
        <>
            <Headers />
            <PortfolioHero />
            <Description />
            <NftMarket />
            <Footer />
        </>
    )
}

export default Portfolio
import React from 'react'

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

import bgImage from 'assets/images/game-covers/game-bg-1.jpg'
import slider_d_1 from 'assets/images/games-list-sliders/games-list-slider-d-1.jpg'
import slider_m_1 from 'assets/images/games-list-sliders/games-list-slider-m-1.jpg'
import slider_d_2 from 'assets/images/games-list-sliders/games-list-slider-d-2.jpg'
import slider_m_2 from 'assets/images/games-list-sliders/games-list-slider-m-2.jpg'

const data = [
    { id: 1, imgUrlDesk: slider_d_1, imgUrlMobile: slider_m_1 },
    { id: 2, imgUrlDesk: slider_d_2, imgUrlMobile: slider_m_2 }
];

const HerobgImage = () => {
    return (
        <>
            <div className="hero-bg-image" style={{ backgroundImage: `url(${bgImage})` }}>
                <div className="container">
                    <div className="inner">
                        <div className="text-content">
                            <h2>Discover The Latest Games On
                                <br />
                                <span>Planet Memes</span>
                            </h2>
                        </div>
                        <div className="image-contnet mt-5">
                            <Swiper
                                spaceBetween={15}
                                slidesPerView={1}
                                navigation={true}
                                pagination={{ clickable: true }}
                                loop={true}
                                effect="fade"
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                modules={[Navigation, Autoplay, Pagination, EffectFade]}
                                className="swiper-gameHeader"
                            >
                                {data.map((item) => (
                                    <SwiperSlide key={item.id}>
                                        <div className="img-inner">
                                            <picture>
                                                <source media="(max-width: 599px)" srcSet={item.imgUrlMobile} />
                                                <source media="(min-width: 600px)" srcSet={item.imgUrlDesk} />
                                                <img loading="lazy"  src={item.imgUrlDesk} alt="alt" />
                                            </picture>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HerobgImage
import React, { useEffect, useState } from "react";
import Headers from "components/Layouts/Header";
import Footer from "components/Layouts/Footer";
import GameHeaderInfo from "./GameHeaderInfo";
import GameDetailsHero from "components/Layouts/Heros/GameDetailsHero";
import { useParams } from "react-router-dom";
import { GamesInformation } from "Mock/GamesInformation";

const GameDetails = () => {
  const { id } = useParams();
  const [currentGame, setCurrentGame] = useState(GamesInformation[0]);

  useEffect(() => {
    const getGameInfo = () => {
      // eslint-disable-next-line eqeqeq
      const game = GamesInformation.filter((game) => game.id == id);
      if (game?.length) setCurrentGame(game[0]);
    };
    getGameInfo();
  }, [id]);

  return (
    <>
      <Headers />
      <GameDetailsHero heroImageUrl={currentGame.screenShots[0]} />
      <GameHeaderInfo index={GamesInformation.indexOf(currentGame)} />
      <Footer />
    </>
  );
};

export default GameDetails;

import React, { useState } from "react";

// import Filters from './Filters'
import { GamesInformation } from "Mock/GamesInformation";
import CardGame from "components/Layouts/Cards/CardGame";
import { useNavigate } from "react-router-dom";

const CardGamesList = () => {
  const navigate = useNavigate();
  const [filterside, setFilterside] = useState(false);

  const handleCurrentPage = (id) => {
    navigate(`/game_details/${id}`);
  };

  return (
    <>
      <div className="card-gameslist-section">
        <div className="container-lg">
          <div className="section-inner">
            <div className="grid-layout">
              {/* <Filters filterside={filterside} setFilterside={setFilterside} /> */}
              <div className="cards-list">
                <div className="mini-titles">
                  <h6 className="m-title">Games List</h6>
                  <button
                    type="button"
                    className="btn-show-filter"
                    onClick={() => setFilterside(true)}
                  >
                    <i className="fi fi-rr-filter"></i>Show Filters
                  </button>
                </div>
                <div className="inner">
                  {GamesInformation.map(game => (
                    <CardGame
                      key={game.id}
                      name={game.name}
                      genre={game.genre}
                      cover={game.cover}
                      pageUrl={game.pageUrl}
                      handleCurrentPage={() => handleCurrentPage(game.id)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardGamesList;

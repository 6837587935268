import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";

import imgCover from "assets/images/game-covers/cover-01.jpg";
import { Button } from "components/Common";
import { GamesInformation } from "Mock/GamesInformation";

const GameHeaderInfo = ({ index }) => {
  const currentGame = GamesInformation[index];
  return (
    <>
      <div className="gameHederinfo-section">
        <div className="container-lg">
          <div className="section-inner">
            <div className="grid-layout">
              <div className="game-gallery">
                <Swiper
                  spaceBetween={15}
                  slidesPerView={1}
                  navigation={true}
                  pagination={{ clickable: true }}
                  loop={true}
                  autoplay={{
                    delay: 50000,
                    disableOnInteraction: false,
                  }}
                  modules={[Navigation, Autoplay, Pagination]}
                  className="swiper-gameHeader"
                >
                  {currentGame.screenShots.map((item) => (
                    <SwiperSlide key={item}>
                      <div className="img-inner">
                        <img loading="lazy" src={item} alt="alt" />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div className="game-description">
                {/* <div className="game-actions">
                  <ul>
                    <li>
                      <i className="fi fi-rr-download"></i>
                      {currentGame.downloads}
                    </li>
                  </ul>
                  <Button
                    title="Download Game"
                    haveHref={false}
                    haveIcon={false}
                    className="btn-primary"
                  />
                </div> */}
                <h3>Introduction</h3>
                <p>{currentGame.desc}</p>
              </div>
              <div className="game-information">
                <div className="info-inner">
                  <div className="cover">
                    <img
                      loading="lazy"
                      src={currentGame.cover}
                      alt="game-name"
                    />
                  </div>
                  <div className="info-list">
                    <div className="game-name">
                      <h2>{currentGame.name}</h2>
                    </div>
                    <div className="list-inner">
                      <ul>
                        <li>
                          <span className="name">Developer</span>
                          <span className="desc">{currentGame.developer}</span>
                        </li>
                        <li>
                          <span className="name">Rating</span>
                          <span className="desc">
                            <i className="fi fi-sr-star"></i>
                            {currentGame.rating}
                          </span>
                        </li>
                        <li>
                          <span className="name">Network</span>
                          <span className="desc">{currentGame.network}</span>
                        </li>
                        <li>
                          <span className="name">Release Status</span>
                          <span className="desc">{currentGame.release}</span>
                        </li>
                        <li>
                          <span className="name">Game Download</span>
                          <span className="desc">
                            {currentGame.platforms.includes("mobile") && (
                              <small>
                                <i className="fi fi-rr-smartphone"></i>
                              </small>
                            )}
                            {currentGame.platforms.includes("pc") && (
                              <small>
                                <i className="fi fi-rr-screen"></i>
                              </small>
                            )}
                          </span>
                        </li>
                      </ul>
                      {/* <div className="tags">
                        <ul>
                          {currentGame.tags.map((tag) => (
                            <li key={tag}>{tag}</li>
                          ))}
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GameHeaderInfo;

import Footer from 'components/Layouts/Footer'
import Headers from 'components/Layouts/Header'
import React from 'react'
import Rewards from './Rewards'
import HowInvite from './HowInvite'
import ReferralHero from './ReferralHero'
import Faqs from './Faqs'

const RefferalLanding = () => {
    return (
        <>
            <Headers haveBg={false} />
            <ReferralHero />
            <HowInvite />
            <Rewards />
            <Faqs />
            <Footer />
        </>
    )
}

export default RefferalLanding
import { Link } from "react-router-dom"

export const CardNft = ({ walletAddress, imgUrl, userName, inscription, notforSale, btc }) => {
    return (
        <div className="card-nft">
            <div className="card-inner">
                <div className="thumb">
                    <Link to=''>
                        <img src={imgUrl} alt="nft-name" />
                    </Link>
                    <div className="wallet-address">
                        <Link to=''>{walletAddress}</Link>
                    </div>
                </div>
                <div className="info">
                    <h3 className="user-name">
                        <Link to=''>{userName}</Link>
                        <span className='icon'><i className='fi fi-rr-check'></i></span>
                    </h3>
                    <h4 className="inscription">
                        <Link to="">Inscription #{inscription}</Link>
                    </h4>
                </div>
                <div className="c-footer">
                    {notforSale ? (
                        <>
                            <h5>{btc} <span>btc</span></h5>
                            <div className="btn-group">
                                <div className="date"><i className='fi fi-rr-clock'></i>a month ago</div>
                                <button className='btn-payment'>Buy now</button>
                            </div>
                        </>
                    ) : <h5>not for sell</h5>}
                </div>
            </div>
        </div>
    )
}

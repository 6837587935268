import { useEffect, useRef, useState } from "react";
import { Button } from "components/Common";

import vidPitbull from "assets/videos/home-hero-2.mp4";
import ReactTypingEffect from "react-typing-effect";

const HomeHero = () => {
  const [loading, setLoading] = useState(true);
  const videoRef = useRef();

  const handleLoadedData = () => {
    setLoading(false);
  };

  return (
    <>
      <div className="hero-home-section">
        <div className="overlay"></div>
        {loading && <div className="img-content"></div>}

        <video
          ref={videoRef}
          src={vidPitbull}
          onLoadedData={handleLoadedData}
          autoPlay
          loop
          muted
        ></video>
        <div className="text-content">
          <div className="text-inner">
            <h1>
              Enhancing the power of Meme Coins through play
              {/* <ReactTypingEffect
                text={[
                  "Enhancing the power of Meme Coins through play",
                  "Clash of Memes is A Web3 Masterpiece!",
                ]}
                speed={40}
                eraseSpeed={40}
                eraseDelay={1000}
                typingDelay={1500}
              /> */}
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeHero;

import React from 'react'


const rewardsData = [
    { id: 1, title: 'Farms Referral Rewards', desc: 'Gain 5% from your friends earnings on Farms! Your rewards will be displayed on the referral balance at the moment your invited friends withdraw their earned BSW tokens.' },
    { id: 2, title: 'Launchpools Referral Rewards', desc: 'Get 5% of from friends’ profit obtained in Launchpools! The reward is only valid for the pool in which BSW is staked in return for more BSW.' },
    { id: 3, title: 'Swaps Referral Rewards', desc: 'Get up to 20% from friends’ swap commission each time your friend makes a swap! Receive your reward immediately after the swap is made. Swaps referral program will be active for certain pairs only.' },
]

const Rewards = () => {
    return (
        <div className="get-rewards-section">
            <div className="container">
                <div className="section-inner">
                    <div className="row justify-content-center">
                        {
                            rewardsData.map(item => (
                                <div className="col-lg-4 col-md-6" key={item.id}>
                                    <div className="card-reward">
                                        <div className="card-inner">
                                            <div className="info">
                                                <h6>{item.title}</h6>
                                                <p>{item.desc}</p>
                                            </div>
                                            <a href='#' className='btn btn-link'>Read more<i className='fi fi-rr-arrow-alt-circle-right'></i></a>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Rewards
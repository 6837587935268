import React from "react";
import ImageContent from "./ImageContent";
import TextContent from "./TextContent";

import buyImage_1 from "assets/images/buycoin/buycoin-image-1.png";
import buyImage_2 from "assets/images/buycoin/buycoin-image-2.png";
import buyImage_3 from "assets/images/buycoin/buycoin-image-3.png";
import buyImage_4 from "assets/images/buycoin/buycoin-image-4.png";
import buyImage_5 from "assets/images/buycoin/buycoin-image-5.png";

const TabImages = ({ showBuycoinTab, isConnected }) => {
  const renderSwitch = (value) => {
    if (showBuycoinTab === "wallet-buy" && isConnected)
      return <ImageContent src={buyImage_2} alt="img-name" />;
    switch (showBuycoinTab) {
      case "wallet-connect":
        return <TextContent />;
      case "wallet-choose":
        return <ImageContent src={buyImage_1} alt="img-name" />;
      case "wallet-ETH":
        return <ImageContent src={buyImage_3} alt="img-name" />;
      case "wallet-USDT":
        return <ImageContent src={buyImage_4} alt="img-name" />;
      case "wallet-status":
        return <ImageContent src={buyImage_5} alt="img-name" />;
      default:
        return <TextContent />;
    }
  };

  return <>{renderSwitch()}</>;
};

export default TabImages;

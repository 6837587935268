import React from "react";

import Logo from "../../../assets/images/logo.png";
import DrawerLinks from "./DrawerLinks";

const Drawer = ({ showDrawer, setShowDrawer }) => {
  return (
    <>
      <div id="sidebar-menu" className={showDrawer ? "active" : ""}>
        <div className="sidebar-header">
          <div className="sidebar-logo">
            <a href="index.html">
              <img loading="lazy" src={Logo} className="sidebarlogo" alt="" />
            </a>
          </div>
          <div className="sidebar-close">
            <button
              className="btn-close-menu"
              onClick={() => setShowDrawer(false)}
            >
              <i className="fi fi-rr-cross"></i>
            </button>
          </div>
        </div>
        <div className="category-list">
          <DrawerLinks />
        </div>
        <div className="social-icons">
          <ul>
            <li>
              <a href="https://discord.gg/s4rFThfkXG">
                <i className="fi fi-brands-discord"></i>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCuGGp_yj6jjsWC6t914_1cQ">
                <i className="fi fi-brands-youtube"></i>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/PlanetMemes4">
                <i className="fi fi-brands-twitter"></i>
              </a>
            </li>
            <li>
              <a href="https://t.me/PlanetMemesOf">
                <i className="fi fi-brands-telegram"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        className={`overlay-back ${showDrawer ? "active" : ""}`}
        onClick={() => setShowDrawer(false)}
      ></div>
    </>
  );
};

export default Drawer;

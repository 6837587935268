import React from "react";

const CardUserBoard = ({ rate, imgUrl, userName, point }) => {
  return (
    <div className="card-userboard">
      <div className="inner">
        <div className="num">
          <span>{rate}</span>
        </div>
        <div className="medal">
          <img loading="lazy" src={imgUrl} alt="user-image" />
        </div>
        <div className="user-name">
          <h6>{userName}</h6>
        </div>
        <div className="point">$ {point}</div>
      </div>
    </div>
  );
};

export default CardUserBoard;

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/bundle";
import "swiper/css/navigation";
import { Autoplay, EffectCreative, Navigation } from "swiper";


import screen1 from "assets/images/scrrenshots/screenshot-1.png";
import screen2 from "assets/images/scrrenshots/screenshot-2.png";
import screen3 from "assets/images/scrrenshots/screenshot-3.png";
import screen4 from "assets/images/scrrenshots/screenshot-4.png";
import screen5 from "assets/images/scrrenshots/screenshot-5.png";

import { Button } from "components/Common";

const AppScreenSlider = () => {

  useEffect(() => {
    AOS.init();
    AOS.refresh()
  }, [])

  return (
    <>
      <div className="application-screenshots-section">
        <div className="container">
          <div className="seciton-inner">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="text-contnet" data-aos="fade-down">
                  <h1>
                    THE NEXT GENERATION OF GAMING WALLETS FOR
                    <br />
                    <span>MEME COINS </span>LOVERS
                  </h1>
                  <p>
                    Join the fun of Planet Memes games and NFTs with a simple email sign-up. Create your wallet now and start playing!
                  </p>
                  <div className="buttons-group mt-5">
                    <Button
                      title="Download from Google play"
                      className="btn-primary"
                      haveHref={true}
                      url="https://play.google.com/store/apps/details?id=com.memeswallet"
                      haveIcon={true}
                      icon="fi-rr-wallet"
                      iconPosition="right"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-padding">
                <div className="image-content">
                  <Swiper
                    slidesPerView={2}
                    navigation={true}
                    effect={"creative"}
                    creativeEffect={{
                      prev: {
                        shadow: true,
                        translate: [0, 0, -400],
                      },
                      next: {
                        translate: ["100%", 0, 0],
                      },
                    }}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                    modules={[EffectCreative, Navigation, Autoplay]}
                    className="swiper-appScreenshot"
                  >
                    <SwiperSlide>
                      <img loading="lazy" src={screen1} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img loading="lazy" src={screen2} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img loading="lazy" src={screen3} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img loading="lazy" src={screen4} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img loading="lazy" src={screen5} alt="" />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppScreenSlider;

import React from "react";

const TextDescription = () => {
  return (
    <div className="text-description-section">
      <div className="container">
        <div className="section-inner">
          <h2>Terms Of Service</h2>
          <h2>Effective as of September 16, 2022</h2>
          <p>
            Terms of Service (the “Terms” or the “Agreement”) carefully, along
            with any other policies or notices on Memes Wallet's website or
            mobile applications, and our Privacy Policy. Together, these
            materials contain terms, rules, and guidelines related to your use
            of Memes Wallet.
          </p>
          <h2>Overview</h2>
          <p>
            This section provides a brief summary of the highlights of this
            Agreement. Please note that when you accept this Agreement, you are
            accepting all of the terms and conditions and not just this section.
            Memes Wallet provides self-hosted wallet services for Bitcoin,
            Ethereum-based blockchains and other protocols. By creating or
            importing a wallet, downloading or running our mobile application,
            or visiting our website, you are agreeing to our Terms, so please
            read carefully. These terms outline approved uses of Memes Wallet,
            various licenses that we grant to you, and licenses that you grant
            us. If you have any questions or comments related to this Agreement,
            please send us a message on our website at support@planetmemes.org
            contact us through Facebook, Telegram, or Twitter. If you do not
            agree to this Agreement or any modifications to this Agreement, you
            should not use our Services.
          </p>

          <h2>How you accept this policy</h2>
          <p>
            By creating or importing a locally hosted wallet (a “Wallet”), or
            visiting our website, you acknowledge that you have read,
            understood, and agreed to these Terms, as well as the accompanying
            [Privacy Policy] We reserve the right to change these Terms at any
            time. Any such changes with respect to your use of Memes Wallet will
            take effect immediately when posted on our website or on our mobile
            application. Your continued use of Memes Wallet following any such
            change will signify your acceptance to be bound by the then current
            Terms. Please check the effective date above to determine if there
            have been any changes since you have last reviewed these Terms. If
            you do not agree to this Agreement or any modifications to this
            Agreement, you should not use Memes Wallet. Failure or delay by
            Memes Wallet in enforcing or partially enforcing any provision of
            these Terms shall not be construed as a waiver of any rights.
          </p>

          <h2>Eligibility</h2>
          <p>
            To be eligible to use Memes Wallet, you must be at least eighteen
            (18) years old and be able to form legally binding contracts. If you
            are using our Services on behalf of a legal entity, you further
            represent and warrant that: (a) the legal entity is duly organized
            and validly existing under the applicable laws of the jurisdiction
            of its organization; and (b) you are duly authorized by such legal
            entity to act on its behalf. You can only use our Services if
            permitted under the laws of your jurisdiction. Please make sure that
            these Terms are in compliance with all laws, rules, and regulations
            that apply to you. By using Memes Wallet, you represent and warrant
            that you meet all eligibility requirements that we outline in these
            Terms. We may still refuse to let certain people access or use Memes
            Wallet, however, and we reserve the right to change our eligibility
            criteria at any time.
          </p>

          <h2>The blockchain services</h2>
          <p>
            Memes Wallet is software that (a) generates Wallet addresses and
            encrypted private keys that you may use to send and receive
            Ethereum,Bitcoin and related cryptographically and secured tokens
            (the “Virtual Currency”); (b) allows users to browse third party
            decentralized applications (“DApps” or “DApp”) through the mobile
            application's DApp browser; and (c) facilitates the submission of
            Virtual Currency transaction data to Ethereum-based blockchains (the
            “Ethereum Networks”) bitcoin and other coins without requiring you
            to download or install the associated blockchain-based software to
            your local device. Wallet Address, Private Key, and Backup
            Capabilities. An encrypted backup of certain information associated
            with the Wallet can be stored on your device in Keystore JSON
            format. The private key is connected to the Wallet address and,
            together, they can be used to authorize the transfer of Virtual
            Currency to and from that Wallet address. You are solely responsible
            for maintaining the security of your private key and any mnemonic
            (backup) phrase associated with your Wallet. You must keep your
            Wallet address, mnemonic (backup) phrase, and private key access
            information secure. Failure to do so may result in the loss of
            control of Virtual Currency associated with the Wallet. Memes Wallet
            Cannot Assist With Password Retrieval. Memes Wallet stores your
            Wallet address but does not receive or store your Wallet password,
            encrypted private key, unencrypted private key, or mnemonic (backup)
            phrase associated with your Wallet. We cannot, therefore, assist you
            with Wallet password retrieval. We cannot generate a new password
            for your Wallet if you fail to remember your original password. If
            you have not safely stored a backup of any Wallet address and
            private key pairs maintained in your Wallet, you accept and
            acknowledge that any Virtual Currency you have associated with such
            a Wallet address will become inaccessible if you do not have your
            Wallet password. Virtual Currency Transactions. In order to be
            completed, all proposed Virtual Currency transactions must be
            confirmed and recorded in the Virtual Currency's associated public
            blockchain. Such networks are decentralized, peer-to-peer networks
            supported by independent third parties, which we do not own,
            control, or operate. We have no control over the Ethereum Networks
            or any blockchain and, therefore, cannot and do not ensure that any
            transaction details that you submit via our Services will be
            confirmed and processed. By using Memes Wallet, you acknowledge and
            agree that the transaction details you submit may not be completed,
            or may be substantially delayed, by any Networks. Memes Wallet Does
            Not Store or Transmit Virtual Currency. We do not store, send, or
            receive Virtual Currency. Any transfer that occurs in any Virtual
            Currency occurs on the blockchain and not on a network owned by us.
            We therefore do not guarantee that Memes Wallet can affect the
            transfer of title or right in any Virtual Currency. Accuracy of
            Information Provided by User. You represent and warrant that any
            information you provide via the Services is accurate and complete.
            You accept and acknowledge that we are not responsible for any
            errors or omissions that you make in connection with any Virtual
            Currency transaction initiated via the Services. We strongly
            encourage you to review your transaction details carefully before
            attempting to transfer a Virtual Currency.
          </p>

          <h2>Wallet registration and account information</h2>
          <p>
            You must either import or create a Wallet in order to use Memes
            Wallet. When you create a Wallet, you will be assigned a private
            key. You will be prompted to download and save a keystore - your
            private key encrypted with a password. You will be responsible for
            maintaining the confidentiality of your private key and keystore,
            and will be fully responsible for any and all activities that occur
            under your account. You agree to immediately notify us of any
            unauthorized use of your password, account, or any other breach of
            security. We will not be liable for any loss or damage arising from
            your failure to comply with this paragraph. When you create a
            Wallet, you are strongly advised to take precautions in order to
            avoid loss of access to and/or control over your Wallet. Suggested
            measures include, but are not limited to, the following: (a)
            creating a strong password that you do not use for any other website
            or online service; (b) using the backup functionality provided by
            the Wallet or safeguard your private key and mnemonic (backup)
            phrase on an external hard drive which, all users are encouraged to
            do even if they are utilizing the Services backup functionality; (c)
            maintaining the security of your Wallet by protecting the private
            key and mnemonic (backup) phrase associated with your Wallet by, for
            example, limiting access to your mobile and your Wallet; and (d)
            promptly notifying us if you discover or otherwise suspect any
            security breaches related to your Wallet. Push Notifications. You
            may agree to receive push notifications from Memes Wallet that will
            alert you when blockchains are congested and when transactions
            involving your Wallet have been completed. Push notifications can be
            enabled to display information about Token Launches. If you would
            like to receive push notifications, you must opt in to the service
            by accessing “Settings” and enabling “Push Notifications”. Payment
            and Fees. Memes Wallet does not currently charge any fees for any of
            its Services. However, we reserve the right to do so in the future
            and, in such case, any applicable fees will be displayed prior to
            you using any service to which a fee applies. Transactions. Once
            transaction details have been submitted via the Services, we cannot
            assist you to cancel or otherwise modify your transaction. Memes
            Wallet has no control over any blockchain and does not have the
            ability to facilitate any cancellation or modification requests. You
            must ensure that you have an adequate balance in your Wallet and/or
            gas to complete transactions before initiating a transaction. You
            acknowledge and agree that we will not be liable for any failed
            transactions due to insufficient funds or gas associated with your
            Memes Wallet account. Taxes. It is your responsibility to determine
            what, if any, taxes apply to the transactions that you have
            submitted transaction details for via the Services, and it is your
            responsibility to report and remit the correct tax to the
            appropriate tax authority. You agree that we are not responsible for
            determining whether taxes apply to your blockchain transactions or
            for collecting, reporting, withholding, or remitting any taxes
            arising from any Virtual Currency-related transactions.
          </p>

          <h2>Third party services and content</h2>
          <p>
            In no event shall a description or reference to a third party's
            product or service (including, but not limited to, providing a
            description or reference via hyperlink) be construed as an
            endorsement or promotion of such third party products or services by
            us. We retain the exclusive right to add to, modify, or cancel the
            availability of any Third Party Service. You may agree to receive
            push notifications from Third Party Content providers. In order to
            receive push notifications, you must opt into the service. Push
            notifications will not be automatically enabled on your device for
            Third Party Content. We do not control, endorse, or adopt any Third
            Party Content shared through push notifications, and will have no
            responsibility for Third Party Content including, but not limited
            to, token availability and/or sales. If, to the extent permitted by
            Memes Wallet, you grant express permission to a third party to
            access or connect to your Memes Wallet account, either through the
            third party's product or service or through Memes Wallet, you
            acknowledge that granting permission to a third party to take
            specific actions on your behalf does not relieve you of any of your
            responsibilities under this Agreement. You are fully responsible for
            all acts or omissions of any third party with access to your Memes
            Wallet account.
          </p>

          <h2>Your use of Memes Wallet</h2>
          <p>
            As a user of the Services, we grant you a limited, personal,
            non-commercial, non-exclusive, non-transferable, and revocable
            license to use Memes Wallet. When using our Services, we ask that
            you follow some basic rules: Don't Use Our Services to Break the
            Law. You agree that you will not violate any laws when using our
            Services. This includes any local, provincial, state, federal,
            national, or international laws that may apply to you. You agree
            that you will not use our Services to pay for, support, or otherwise
            engage in any illegal activities including, but not limited to,
            illegal gambling, fraud, money laundering, or terrorist activities.
            If we discover that you have violated this Agreement or other
            regulatory requirements including, but not limited to, the Bank
            Secrecy Act, by participating in money laundering or by financing
            terrorist activities, we will take proportional disciplinary action.
            You further agree not to encourage or induce any third party to
            engage in any of the activities prohibited under this Section. Don't
            Interfere With Other's Use of the Services. You agree that you will
            not use or attempt to use another user's Wallet without
            authorization or use our Services in any manner that could
            interfere, disrupt, negatively affect, or inhibit other users from
            fully enjoying it. Don't Try to Harm Our System. You agree not to
            distribute any virus or other harmful computer code through Memes
            Wallet. You also agree to not take any action that may impose an
            unreasonable or disproportionately large load on our or any of our
            third party providers' infrastructure. Don't Attempt to Circumvent
            Our Security. You agree not to bypass, circumvent, or attempt to
            bypass or circumvent any measures that we may use to prevent or
            restrict access to the Services including, without limitation, other
            accounts, computer systems, or networks connected to the Services.
            Any use of Memes Wallet other than as specifically authorized in
            this Agreement, without our prior written permission, is strictly
            prohibited and will terminate your license to use Memes Wallet.
          </p>

          <h2>Limitation of liability & disclaimer of warranties</h2>
          <p>
            You understand and agree that we have no control over, and no duty
            to take any action regarding: Failures, disruptions, errors, or
            delays in processing Virtual Currency that you may experience while
            using the Services; The risk of failure of hardware, software, and
            Internet connections; The risk of malicious software being
            introduced or found in the software underlying Memes Wallet; The
            risk that third parties may obtain unauthorized access to
            information stored within your Wallet, including, but not limited to
            your Wallet address, private key, and mnemonic (backup) phrase; and
            The risk of unknown vulnerabilities in or unanticipated changes to
            the Bitcoin, Ethereum, or any Network. You release us from all
            liability related to any losses, damages, or claims arising from:
            (a) user error such as forgotten passwords, incorrectly constructed
            transactions, or mistyped Virtual Currency addresses; (b) server
            failure or data loss; (c) unauthorized access to the Memes Wallet
            application; (d) bugs or other errors in the Memes Wallet software;
            and (e) any unauthorized third party activities, including, but not
            limited to, the use of viruses, phishing, brute forcing, or other
            means of attack against Memes Wallet. We make no representations
            concerning any Third Party Content contained in or accessed through
            our Services. Any other terms, conditions, warranties, or
            representations associated with such content, are solely between you
            and such organizations and/or individuals.
          </p>

          <h2>Limitation of Liability</h2>
          <p>
            To the fullest extent permitted by applicable law, in no event will
            we or any of our officers, directors, representatives, agents,
            servants, counsel, employees, consultants, lawyers, and other
            personnel authorized to act, acting, or purporting to act on our
            behalf (collectively the “dapps platform parties”) be liable to you
            under contract, tort, strict liability, negligence, or any other
            legal or equitable theory, for: (a) any lost profits, data loss,
            cost of procurement of substitute goods or services, or direct,
            indirect, incidental, special, punitive, compensatory, or
            consequential damages of any kind whatsoever resulting from: (i)
            your use of, or conduct in connection with, our services; (ii) any
            unauthorized use of your wallet address and/or private key due to
            your failure to maintain the confidentiality of your wallet; (iii)
            any interruption or cessation of transmission to or from the
            services; or (iv) any bugs, viruses, trojan horses, or the like that
            are found in the Memes Wallet software or that may be transmitted to
            or through our services by any third party (regardless of the source
            of origination), or (b) any direct damages in excess of (in the
            aggregate) of the greater of: (i) fees paid to us for the applicable
            products. These limitations apply regardless of legal theory,
            whether based on tort, strict liability, breach of contract, breach
            of warranty, or any other legal theory, and whether or not we were
            advised of the possibility of such damages. Some jurisdictions do
            not allow the exclusion or limitation of liability for consequential
            or incidental damages, so the above limitation may not apply to you.
          </p>

          <h2>Warranty Disclaimer</h2>
          <p>
            Memes Wallet is provided 'as is' and without warranty of any kind.
            To the maximum extent permitted by law, we disclaim all
            representations and warranties, express or implied, relating to the
            services and underlying software or any content on the services,
            whether provided or owned by us or by any third party, including
            without limitation, warranties of merchantability, fitness for a
            particular purpose, title, non-infringement, freedom from computer
            virus, and any implied warranties arising from course of dealing,
            course of performance, or usage in trade, all of which are expressly
            disclaimed. In addition, we do not represent or warrant that the
            content accessible via the services is accurate, complete,
            available, current, free of viruses or other harmful components, or
            that the results of using the services will meet your requirements.
            Some states do not allow the disclaimer of may not apply to you.
            This paragraph gives you specific legal rights and you may also have
            other legal rights that vary from state to state.
          </p>

          <h2>Force majeure</h2>
          <p>
            We shall not be held liable for any delays, failure in performance,
            or interruptions of service which result directly or indirectly from
            any cause or condition beyond our reasonable control, including but
            not limited to: any delay or failure due to any act of God, act of
            civil or military authorities, act of terrorism, civil disturbance,
            war, strike or other labor dispute, fire, interruption in
            telecommunications or Internet services or network provider
            services, failure of equipment and/or software, other catastrophe,
            or any other occurrence which is beyond our reasonable control and
            shall not affect the validity and enforceability of any remaining
            provisions.
          </p>

          <h2>Assignment</h2>
          <p>
            You agree that we may assign any of our rights and/or transfer,
            sub-contract, or delegate any of our obligations under these Terms.
            Your agreement to these Terms is personal to you and you may not
            transfer or assign it to any third party.
          </p>

          <h2>Entire agreement</h2>
          <p>
            This Agreement sets forth the entire understanding and agreement as
            to the subject matter hereof and supersedes any and all prior
            discussions, agreements, and understandings of any kind (including,
            without limitation, any prior versions of this Agreement) and every
            nature between us. Except as provided for above, any modification to
            this Agreement must be in writing and must be signed by both
            parties.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TextDescription;

import React, { useEffect, useRef, useState } from "react";

import LoadingImg from "assets/lottie/loading-Infinity.svg";
import SuccessImg from "assets/lottie/success.svg";
import ErrorImg from "assets/lottie/error.svg";
import useOnClickOutside from "hooks/useOnClickOutside";

//NOTE state is one of ["Error","Success","Laoding"]
const Loading = ({ title, showModal, state, closeModal }) => {
  const [img, setImg] = useState(LoadingImg);
  const ref = useRef();

  useOnClickOutside(ref, () => closeModal());
  useEffect(() => {
    console.log(state);
    if (state === "error") setImg(ErrorImg);
    if (state === "success") setImg(SuccessImg);
    if (state === "loading") setImg(LoadingImg);
  }, [state, title, showModal]);
  return (
    <>
      <div
        ref={ref}
        className={`modal modal-loading fade ${showModal ? "show" : ""}`}
        tabIndex="-1"
        data-bs-backdrop="static"
      >
        <div className={`modal-backdrop fade ${showModal ? "show" : ""}`}></div>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              onClick={() => closeModal()}
            ></button>
            <div className="modal-body">
              <div className="icon">
                <img loading="lazy" src={img} alt="loading..." />
              </div>
              <h6>{title}</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loading;

import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/bundle";
import { Autoplay, Pagination } from "swiper";

import gameCard_img_1 from "assets/images/game-covers/char-01.png";
import gameCard_img_2 from "assets/images/game-covers/char-02.png";
import gameCard_img_3 from "assets/images/game-covers/char-03.png";
import gameCard_img_4 from "assets/images/game-covers/char-04.png";
import gameCard_img_5 from "assets/images/game-covers/char-05.png";

import char_property_1 from "assets/images/game-covers/char-property-1.png";
import char_property_2 from "assets/images/game-covers/char-property-2.png";
import char_property_3 from "assets/images/game-covers/char-property-3.png";
import char_property_4 from "assets/images/game-covers/char-property-4.png";
import char_property_5 from "assets/images/game-covers/char-property-5.png";

import card_coin_img from "assets/images/game-covers/card-coin.png";
import { Button } from "components/Common";

const MarketPlace = () => {
  return (
    <>
      <div className="marketplace-section">
        <div className="container">
          <div className="page-title">
            <div className="title-inner">
              <h2>Clash of Memes <span>NFTs</span></h2>
            </div>
          </div>
          <div className="section-inner">
            <Swiper
              slidesPerView={1}
              pagination={{ clickable: true }}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 8,
                },
                1199: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                1400: {
                  slidesPerView: 4,
                  spaceBetween: 15,
                },
              }}
              modules={[Autoplay, Pagination]}
              className="swiper-marketplace"
            >
              <SwiperSlide>
                <div className="card-char-details color-epic">
                  <div className="card-inner">
                    <div className="thumb">
                      <div className="img-inner">
                        <img loading="lazy" src={gameCard_img_1} alt="" />
                      </div>
                      <div className="char-property">
                        <ul>
                          <li>
                            <img loading="lazy" src={char_property_3} alt="" />
                          </li>

                          <li>
                            <img loading="lazy" src={char_property_5} alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="info">
                      <div className="category">
                        <h6>Legendary</h6>
                      </div>
                      <h2 className="name">Iron Elon</h2>
                      <div className="price-content">
                        <div className="battle">
                          <h6>Levels</h6>
                          <h5>9-14</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card-char-details color-legendary">
                  <div className="card-inner">
                    <div className="thumb">
                      <div className="img-inner">
                        <img loading="lazy" src={gameCard_img_2} alt="" />
                      </div>
                      <div className="char-property">
                        <ul>
                          <li>
                            <img loading="lazy" src={char_property_2} alt="" />
                          </li>

                          <li>
                            <img loading="lazy" src={char_property_5} alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="info">
                      <div className="category">
                        <h6>Rare</h6>
                      </div>
                      <h2 className="name">Elite Floki</h2>
                      <div className="price-content">
                        <div className="battle">
                          <h6>Levels</h6>
                          <h5>3-14</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card-char-details color-common">
                  <div className="card-inner">
                    <div className="thumb">
                      <div className="img-inner">
                        <img loading="lazy" src={gameCard_img_3} alt="" />
                      </div>
                      <div className="char-property">
                        <ul>
                          <li>
                            <img loading="lazy" src={char_property_1} alt="" />
                          </li>
                          <li>
                            <img loading="lazy" src={char_property_2} alt="" />
                          </li>

                          <li>
                            <img loading="lazy" src={char_property_4} alt="" />
                          </li>
                          <li>
                            <img loading="lazy" src={char_property_5} alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="info">
                      <div className="category">
                        <h6>Epic</h6>
                      </div>
                      <h2 className="name">Shiba Knight</h2>
                      <div className="price-content">
                        <div className="battle">
                          <h6>Levels</h6>
                          <h5>6-14</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card-char-details">
                  <div className="card-inner">
                    <div className="thumb">
                      <div className="img-inner">
                        <img loading="lazy" src={gameCard_img_4} alt="" />
                      </div>
                      <div className="char-property">
                        <ul>
                          <li>
                            <img loading="lazy" src={char_property_3} alt="" />
                          </li>
                          <li>
                            <img loading="lazy" src={char_property_4} alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="info">
                      <div className="category">
                        <h6>Common</h6>
                      </div>
                      <h2 className="name">Baby Archer</h2>
                      <div className="price-content">
                        <div className="battle">
                          <h6>Levels</h6>
                          <h5>1-14</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card-char-details">
                  <div className="card-inner">
                    <div className="thumb">
                      <div className="img-inner">
                        <img loading="lazy" src={gameCard_img_5} alt="" />
                      </div>
                      <div className="char-property">
                        <ul>
                          <li>
                            <img loading="lazy" src={char_property_3} alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="info">
                      <div className="category">
                        <h6>Epic</h6>
                      </div>
                      <h2 className="name">Iron Hand</h2>
                      <div className="price-content">
                        <div className="battle">
                          <h6>Levels</h6>
                          <h5>6-14</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          {/* <div className="btns-group">
            <Button
              title="Visit MarketPlace"
              className="btn-primary"
              haveHref={true}
              url="/"
              haveIcon={true}
              icon="fi-rr-arrow-right"
              iconPosition="right"
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default MarketPlace;

import React from "react";
import { useAccount } from "wagmi";
import WalletBuy from "./WalletBuy";
import WalletChoose from "./WalletChoose";
import WalletETH from "./WalletETH";
import WalletRemaining from "./WalletRemaining";
import WalletStatus from "./WalletStatus";
import WalletUSDT from "./WalletUSDT";

const TabBox = ({
  showBuycoinTab,
  setshowBuycoinTab,
  connect,
  disconnect,
  isConnected,
}) => {
  const renderSwitch = () => {
    if (showBuycoinTab === "wallet-buy" && isConnected)
      return <WalletBuy setshowBuycoinTab={setshowBuycoinTab} />;
    switch (showBuycoinTab) {
      case "wallet-connect":
        return <WalletRemaining setshowBuycoinTab={setshowBuycoinTab} />;
      case "wallet-choose":
        return <WalletChoose setshowBuycoinTab={setshowBuycoinTab} />;

      case "wallet-ETH":
        return <WalletETH setshowBuycoinTab={setshowBuycoinTab} />;
      case "wallet-USDT":
        return <WalletUSDT setshowBuycoinTab={setshowBuycoinTab} />;
      case "wallet-status":
        return <WalletStatus setshowBuycoinTab={setshowBuycoinTab} />;
      default:
        return <WalletRemaining setshowBuycoinTab={setshowBuycoinTab} />;
    }
  };

  return <>{renderSwitch()}</>;
};

export default TabBox;

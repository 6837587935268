import React from 'react'
import Footer from 'components/Layouts/Footer'
import Headers from 'components/Layouts/Header'
import PageHero from 'components/Layouts/Heros/PageHero'
import TextDescription from './TextDescription'

const TermOfService = () => {
    return (
        <>
            <Headers haveBg={true} />
            <PageHero title="Term OF Services" />
            <TextDescription />
            <Footer />
        </>
    )
}

export default TermOfService
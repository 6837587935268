import React from "react";

const TextContent = () => {
  return (
    <div className="text-content">
      <h3>Planet memes validator round</h3>
      <p>
        Planet Memes is an ecosystem built to reward and give utility to Your
        favourite meme coins, $PMC holders, and contributors to the PMC network.
        This is the validator round which means if you are seeing this you have
        been hand-picked to become a validator for the planet memes network. You
        can buy directly using USDT or ETH already in your wallet. You can also
        use your card. You’ll be able to claim your purchased $PMC tokens after
        the public Pre-sale is finalised.
      </p>
    </div>
  );
};

export default TextContent;

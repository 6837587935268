export const totalTokens = 4_000_000_000;

export const baseDecimals = 18;
export const weiUnit = Math.pow(10, baseDecimals);
export const initailSalePrice = 0.005 * weiUnit;

export const calculatePrice = (inSale, _amount) => {
  let totalSold = totalTokens - inSale;
  let totalValue = 0;
  if (totalSold + _amount <= 10_000_000) {
    return _amount * initailSalePrice;
  } else {
    let extra = totalSold + _amount - 500_000_000;
    let _salePrice = initailSalePrice;

    if (totalSold >= 500_000_000) {
      _salePrice =
        _salePrice +
        0.002 * weiUnit +
        ((totalSold - 500_000_000) / 500_000_000) * (0.002 * weiUnit);

      let period = _amount / 500_000_000;

      if (period === 0) {
        return _amount * _salePrice;
      } else {
        while (period > 0) {
          totalValue = totalValue + 500_000_000 * _salePrice;
          _amount -= 500_000_000;
          _salePrice += 0.002 * weiUnit;
          period--;
        }

        if (_amount > 0) {
          totalValue += _amount * _salePrice;
        }
        return totalValue;
      }
    } else {
      totalValue = (_amount - extra) * _salePrice;
      if (extra <= 500_000_000) {
        return totalValue + extra * ((_salePrice * 120) / 100);
      } else {
        while (extra >= 500_000_000) {
          _salePrice += 0.002 * weiUnit;
          totalValue = totalValue + 500_000_000 * _salePrice;
          extra -= 500_000_000;
        }

        if (extra > 0) {
          _salePrice += 0.002 * weiUnit;
          totalValue += extra * _salePrice;
        }
        return totalValue;
      }
    }
  }
};

export const getBuyWithEth = (amount, realtimeEthPrice, inSale) => {
  const usdPrice = calculatePrice(inSale, amount);
  return (usdPrice * baseDecimals) / realtimeEthPrice;
};

export const getBuyWithUsdt = (amount, inSale) => {
  const usdPrice = calculatePrice(inSale, amount);
  return usdPrice / Math.pow(10, 12);
};

import React from "react";

const Modal = ({
  title,
  icon,
  showModal,
  setShowModal,
  children,
  buttonCancelName,
  buttonAcceptName,
  modalSize,
}) => {
  return (
    <>
      <div className={`modal fade ${showModal ? "show" : ""}`} tabIndex="-1">
        <div
          className={`modal-backdrop fade ${showModal ? "show" : ""}`}
          onClick={() => setShowModal(false)}
        ></div>
        <div className={`modal-dialog modal-dialog-centered ${modalSize}`}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                <i className={`fi ${icon}`}></i>
                {title}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowModal(false)}
              ></button>
            </div>
            <div className="modal-body">{children}</div>
            {(buttonCancelName || buttonAcceptName) && (
              <div className="modal-footer">
                {buttonCancelName && (
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => setShowModal(false)}
                  >
                    <i className="fi fi-rr-cross"></i>
                    {buttonCancelName}
                  </button>
                )}
                {buttonAcceptName && (
                  <button type="button" className="btn btn-primary">
                    <i className="fi fi-rr-check"></i>
                    {buttonAcceptName}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;

import React from 'react'
import Headers from 'components/Layouts/Header'
import Footer from 'components/Layouts/Footer'
import HerobgImage from 'components/Layouts/Heros/HerobgImage'
import CardGamesList from 'components/Pages/GamesList/CardGamesList'

const GamesList = () => {
    return (
        <>
            <Headers />
            <HerobgImage />
            <CardGamesList />
            <Footer />
        </>
    )
}

export default GamesList
import React, { useEffect, useState } from "react";
import { CoinInput } from "components/Common/CoinInput";

import bscIcon from "assets/images/icons/usdt.svg";
import pmcIcon from "assets/images/icons/pmc.png";
import { useUsdtBalance } from "hooks/useUsdtBalance";
import { useAccount, useContractRead, useSigner } from "wagmi";
import {
  checkApproveState,
  getApproveForUsdt,
  handleBuyWithUsdt,
} from "utils/smartcontractHelper";
import { useEthBalance } from "hooks/useEthBalance";
import { presaleContractAddress } from "data/consts";
import { preSaleAbiFunctionsName } from "data/abiFunctions";
import presaleABI from "../../../data/preSaleAbi.json";
import { utils } from "ethers";
import Loading from "components/Common/Loading";
import { handleWeb3Err } from "utils/web3err";

const WalletUSDT = ({ setshowBuycoinTab }) => {
  const { address } = useAccount();
  const { data: signer, status: statusSigner } = useSigner();
  const ethBalance = useEthBalance(address);
  const usdtBalance = useUsdtBalance(address);
  const [pmcAmount, setPMCAmount] = useState(0);
  const [convertedUsdt, setConvertedUsdt] = useState(0);

  const [allowance, setAllowance] = useState(false);

  const [loadingForm, setLoadingFormData] = useState({
    show: false,
    msg: "",
    icon: "",
  });
  const [err, setErr] = useState({
    err: false,
    msg: "",
  });

  const { data: pmcByUsdt, refetch } = useContractRead({
    address: presaleContractAddress,
    abi: presaleABI,
    functionName: preSaleAbiFunctionsName.usdtBuyHelper,
    args: [pmcAmount],
  });
  useEffect(() => {
    try {
      setConvertedUsdt((pmcByUsdt?.toNumber() || 0) / Math.pow(10, 6));
    } catch {}
  }, [pmcByUsdt]);

  useEffect(() => {
    (async () => {
      if (statusSigner !== "success") return;
      const checkAllowance = await checkApproveState(signer, address);
      setAllowance(checkAllowance);
    })();
  }, [statusSigner, address, signer]);

  const handleEnableToken = async () => {
    await getApproveForUsdt(signer);
  };

  const handleSubmitBuy = async () => {
    if (!allowance) {
      setErr({
        err: true,
        msg: "You didin't active USDT token",
      });
    }
    if (usdtBalance === 0 || ethBalance < 0.00001) {
      setErr({
        err: true,
        msg: "Oops! It looks like you don't have enough ETH/USDT to pay for that transaction. Please reduce the amount of IMPT and try again. ",
      });
    }

    if (pmcAmount < 10_000_000) {
      setErr({
        err: true,
        msg: "Min: 10M PMC",
      });
      return;
    }
    setErr({
      err: false,
      msg: "",
    });
    setLoadingFormData({
      icon: "loading",
      msg: "Please wait...",
      show: true,
    });
    handleBuyWithUsdt(signer, pmcAmount)
      .then((tr) => {
        console.log(`TransactionResponse TX hash: ${tr.hash}`);
        tr.wait().then((receipt) => {
          setPMCAmount(0);
          setLoadingFormData({
            icon: "success",
            msg: "Success",
            show: true,
          });
          setTimeout(() => {
            closeLoading();
          }, 6 * 1000);
        });
      })
      .catch((e) => {
        const err = handleWeb3Err(e);
        setLoadingFormData({
          icon: "error",
          msg: err,
          show: true,
        });
        setTimeout(() => {
          closeLoading();
        }, 6 * 1000);
      });
  };

  const closeLoading = () => {
    setLoadingFormData({ show: false, icon: "loading", err: "" });
  };

  return (
    <>
      <div className="form-content">
        <button
          type="button"
          className="btn-back"
          onClick={() => setshowBuycoinTab("wallet-buy")}
        >
          <i className="fi fi-rr-arrow-left"></i>Back
        </button>
        <h6 className="title text-center mb-4">Buy With USDT</h6>
        <CoinInput
          title="Planet Memes Token"
          coinIcon={pmcIcon}
          coinName="PMC"
          onChange={(e) => setPMCAmount(e.target.value)}
          value={pmcAmount}
          showMsg={err.err}
          msgStatus="error"
          msgText={err.msg}
          inputReadOnly={false}
        />
        <CoinInput
          title="USDT Amount"
          coinIcon={bscIcon}
          value={convertedUsdt}
          coinName="USDT"
          showMsg={true}
          inputReadOnly={true}
        />
        {/*      
      <ReferralCodeInput
        title="Referral Code"
        placeholder="write here..."
        showMsg={false}
        msgStatus="error"
        msgText="thumb chose around fifth progress industry floating consonant slabs cowboy snow many"
      /> */}
        <div className="buttons-group mb-0 mt-4">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() =>
              !allowance ? handleEnableToken() : handleSubmitBuy()
            }
          >
            {!allowance ? "Enable" : "Buy"}
          </button>
        </div>
      </div>
      <Loading
        title={loadingForm.msg}
        state={loadingForm.icon}
        showModal={loadingForm.show}
        closeModal={() => closeLoading()}
      />
    </>
  );
};

export default WalletUSDT;

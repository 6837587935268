import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { linksData } from "./NavData";

const NavLinks = ({ haveExtraLink }) => {
  return (
    <ul>
      {linksData?.map(link => (
        <li key={link.id}>
          {link.externallink ? (
            <a href={link.url} target="_blank" rel="noreferrer">
              {link.title}
              {link.haveChild ? (<i className="fi fi-rr-caret-down"></i>) : ''}
            </a>
          ) : (
            <HashLink
              to={link.url}
              target={`${link.tragetBlank ? "_blank" : ""}`}
            >
              {link.title}
              {link.haveChild ? (<i className="fi fi-rr-caret-down"></i>) : ''}
            </HashLink>
          )}
          {link.haveChild ? (
            <>
              {
                <ul className="sub-links">
                  {
                    link.subLinks.map((sublink, index) => (
                      <li key={index}><a href={sublink.url}>{sublink.title}</a></li>
                    ))
                  }
                </ul>
              }
            </>
          ) : (
            null
          )}
        </li>
      )
      )}
      {haveExtraLink && (
        <>
          <li>
            <Link to="/privacy" rel="noreferrer">
              Privacy
            </Link>
          </li>
          <li>
            <Link to="/term_of_service" rel="noreferrer">
              Terms
            </Link>
          </li>
        </>
      )}
    </ul>
  );
};

export default NavLinks;

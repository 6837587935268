import React from 'react'

const CardFaq = ({ id, title, desc, showFaq, setShowFaq }) => {
    return (
        <div className="accordion-item" key={id}>
            <h2 className="accordion-header">
                <button className={`accordion-button ${showFaq === `faq-${id}` ? 'collapsed' : ''}`} type="button" onClick={() => setShowFaq(`faq-${id}`)}>
                    {title}
                </button>
            </h2>
            <div className={`accordion-collapse collapse ${showFaq === `faq-${id}` ? 'show' : ''} `}>
                <div className="accordion-body">
                    <p
                        dangerouslySetInnerHTML={{
                            __html: desc.replace(/\n/g, "<br/>")
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default CardFaq
import React, { useState } from "react";
import { Link } from "react-router-dom";

import CardWalletInfo from "components/Layouts/Cards/CardWalletInfo";
import Table from "components/Layouts/Tables/Table";
import { ReferraTableData } from "Mock/ReferralTableData";
import ReferralCode from "./ReferralCode";
import CardUserBoard from "components/Layouts/Cards/CardUserBoard";

import medalGold from "../../../assets/images/monster-1.png";
import medalSilver from "../../../assets/images/monster-2.png";
import medalbronze from "../../../assets/images/monster-3.png";
import userImageDefault from "../../../assets/images/user-default.png";
import Modal from "components/Layouts/Modal";

const WalletInfo = () => {
  const dataUserRating = [
    { id: 1, username: "Lulu Stone", imgurl: medalGold, point: "9539" },
    { id: 2, username: "Myrtle Lamb", imgurl: medalSilver, point: "7073" },
    { id: 3, username: "Mollie Lawrence", imgurl: medalbronze, point: "4116" },
    {
      id: 4,
      username: "Phoebe Elliott",
      imgurl: userImageDefault,
      point: "3205",
    },
    {
      id: 5,
      username: "Oscar Warren",
      imgurl: userImageDefault,
      point: "2187",
    },
  ];

  const [showTab, setShowTab] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const handleShowTab = (e, value) => {
    e.preventDefault();
    setShowTab(value);
  };

  return (
    <>
      <div className="wallet-section">
        <div className="container-lg">
          <div className="section-inner">
            <div className="user-profile">
              <div className="user-image">
                <img loading="lazy" src={userImageDefault} alt="user" />
              </div>
              <div className="user-name">
                <h6>User Name</h6>
              </div>
            </div>
            <Modal
              title="Disconnect"
              icon="fi-rr-sign-out"
              setShowModal={setShowModal}
              showModal={showModal}
              buttonCancelName="No"
              buttonAcceptName="Yes"
              modalSize="modal-md"
            >
              {
                <div className="msg">
                  <p>Do you want to Disconnect Wallet?</p>
                </div>
              }
            </Modal>

            <div className="row">
              <div className="col-lg-3">
                <div className="wallet-side-menu">
                  <ul>
                    <li className={`${showTab === 0 ? "active" : ""}`}>
                      <Link to="#" onClick={(e) => handleShowTab(e, 0)}>
                        <i className="fi fi-rr-wallet"></i>Wallet Info
                      </Link>
                    </li>
                    <li className={`${showTab === 1 ? "active" : ""}`}>
                      <Link to="#" onClick={(e) => handleShowTab(e, 1)}>
                        <i className="fi fi-rr-badge-percent"></i>Leaderboard
                      </Link>
                    </li>
                    <li>
                      <Link to="#" onClick={() => setShowModal(true)}>
                        <i className="fi fi-rr-link"></i>Disconect Wallet
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-9">
                <div
                  className={`wallet-main info ${
                    showTab === 0 ? "active" : ""
                  }`}
                >
                  <div className="wallet-info">
                    <h2 className="wallet-title">Wallet Info</h2>
                    <div className="row mt-3">
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <CardWalletInfo
                          title="PMC Balance"
                          price="0.00765"
                          comparison="0.000546"
                          arrow="up"
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <CardWalletInfo
                          title="PMC Balance"
                          price="0.00765"
                          comparison="0.000546"
                          arrow="down"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="shopping-table-section">
                    <ReferralCode />
                  </div>
                  <div className="shopping-table-section"></div>
                </div>
                <div
                  className={`wallet-main main ${
                    showTab === 1 ? "active" : ""
                  }`}
                >
                  <div className="shopping-table-section">
                    <div className="title">
                      <h2 className="wallet-title">Leaderboard</h2>
                      <p className="wallet-desc">Top 10 Users</p>
                    </div>
                    <div className="leaderboard-list">
                      {dataUserRating.map((cardDate) => (
                        <CardUserBoard
                          key={cardDate.id}
                          rate={cardDate.id}
                          imgUrl={cardDate.imgurl}
                          userName={cardDate.username}
                          point={cardDate.point}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Disconnect"
        icon="fi-rr-sign-out"
        setShowModal={setShowModal}
        showModal={showModal}
      >
        {
          <div className="msg">
            <p>Do you want to Disconnect Wallet?</p>
          </div>
        }
      </Modal>
    </>
  );
};

export default WalletInfo;

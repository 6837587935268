import React, { useState } from "react";

import { Button } from "components/Common";
import NavLinks from "./NavLinks";
import Drawer from "./Drawer";

import Logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";

const Headers = ({ haveBg }) => {
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <>
      <div className={`navbar ${haveBg ? "have-bg" : ""}`}>
        <div className="container-lg">
          <div className="nav-inner">
            <div className="mobile-menu">
              <button
                type="button"
                className="links btn-mobile-menu"
                onClick={() => setShowDrawer(true)}
              >
                <i className="fi fi-rr-menu-burger"></i>
              </button>
              <Drawer showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
            </div>
            <div className="nav-logo">
              <Link to="/">
                <img loading="lazy" src={Logo} alt="logo" />
              </Link>
            </div>
            <div className="nav-links">
              <NavLinks />
            </div>
            <div className="nav-sign">
              {/* <div className="sign">
                <Button
                  title="WhitePaper"
                  className="btn-accent"
                  haveHref={true}
                  url="https://whitepaper.planetmemes.com"
                  haveIcon={true}
                  icon="fi-rr-wallet"
                  iconPosition="right"
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Headers;

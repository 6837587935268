import React from 'react'

const PageHero = ({ title }) => {
    return (
        <>
            <div className="page-hero">
                <div className="container-lg">
                    <div className="header-inner">
                        <h1>{title}</h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageHero
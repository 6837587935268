import React from "react";

const CardBuyCoin = ({ title, imgUrl, desc }) => {
  return (
    <div className="card-buycoin">
      <div className="card-inner">
        <div className="thumb">
          <img loading="lazy" src={imgUrl} alt={title} />
        </div>
        <div className="info">
          <h5>{title}</h5>
          <p
            dangerouslySetInnerHTML={{
              __html: desc.replace("\n", "<br />"),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CardBuyCoin;

import React from "react";

import charimg from "assets/images/hero-index/character-1-left.png";

const Token = () => {
  return (
    <>
      <div className="token-section">
        <div className="container">
          <div className="section-inner">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="text-content">
                  <div className="page-title">
                    <div className="title-inner">
                      <h2>
                        <span>PMC</span> Tokenomics
                      </h2>
                    </div>
                  </div>
                  <div className="info">
                    <div className="percentage">
                      <h2>36%</h2>
                      <div className="desc">Blacklisted</div>
                    </div>
                    <div className="total-supply">
                      <h4>Total Supply</h4>
                      <h3>420,000,000,000,000</h3>
                    </div>
                  </div>
                  <div className="address">
                    <h5>CONTRACT ADDRESS:</h5>
                    <a
                      href="https://www.dextools.io/app/en/ether/pair-explorer/0x979ae745bdd9812f5dad3281595d122b6bafce9e"
                      rel="noreferrer"
                      target="_blank"
                    >
                      0x0e89367ce28209fe791c5b0dbb9880ea23f546e2
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="image-content">
                  <div className="img-inner">
                    <img src={charimg} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Token;

import React from "react";

const CardInvite = ({ number, imgUrl, title, desc }) => {
  return (
    <div className="card-invite">
      <div className="card-inner">
        <div className="number">{number}</div>
        <div className="thumb">
          <img loading="lazy" src={imgUrl} alt="" />
        </div>
        <div className="info">
          <h6>{title}</h6>
          <p>{desc}</p>
        </div>
      </div>
    </div>
  );
};

export default CardInvite;

export const preSaleAbiFunctionsName = {
  ClaimStartUpdated: "ClaimStartUpdated",
  Initialized: "Initialized",
  OwnershipTransferred: "OwnershipTransferred",
  Paused: "Paused",
  SaleTimeSet: "SaleTimeSet",
  SaleTimeUpdated: "SaleTimeUpdated",
  TokensAdded: "TokensAdded",
  TokensBought: "TokensBought",
  TokensClaimed: "TokensClaimed",
  Unpaused: "Unpaused",
  USDTInterface: "USDTInterface",
  baseDecimals: "baseDecimals",
  buyWithEth: "buyWithEth",
  buyWithUSDT: "buyWithUSDT",
  calculatePrice: "calculatePrice",
  changeClaimStart: "changeClaimStart",
  changeSaleTimes: "changeSaleTimes",
  claim: "claim",
  claimStart: "claimStart",
  endTime: "endTime",
  ethBuyHelper: "ethBuyHelper",
  getLatestPrice: "getLatestPrice",
  hasClaimed: "hasClaimed",
  inSale: "inSale",
  initialize: "initialize",
  owner: "owner",
  pause: "pause",
  paused: "paused",
  renounceOwnership: "renounceOwnership",
  salePrice: "salePrice",
  saleToken: "saleToken",
  startClaim: "startClaim",
  startTime: "startTime",
  totalTokens: "totalTokens",
  transferOwnership: "transferOwnership",
  unpause: "unpause",
  usdtBuyHelper: "usdtBuyHelper",
  userDeposits: "userDeposits",
};

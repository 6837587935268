import { usdtTokenAddress } from "data/consts";
import { useBalance } from "wagmi";

export const useUsdtBalance = (address) => {
  const { data } = useBalance({
    address: address,
    token: usdtTokenAddress,
  });
  if (!data) return 0;
  return data.formatted;
};

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import memeNft_img from "assets/images/pepe-with-cup.jpg";

export default function PepeMatch() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <div className="description-section pt-3 pb-5">
        <div className="container">
          <div className="section-inner">
            <div className="row align-items-center">
              <div className="col-lg-6 order-lg-1 order-md-2 order-2">
                <div
                  className="image-content width-90"
                  data-aos="fade-right"
                >
                  <div className="img-inner">
                    <img src={memeNft_img} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-lg-2 order-md-1 order-1">
                <div className="text-content" data-aos="fade-up">
                  <h2>About Pepe Match</h2>
                  <div className="desc">
                    <p>
                      Experience the ultimate fusion of memes and gaming with
                      Pepe Match Game, a groundbreaking project that brings
                      together the beloved Pepe meme and an exciting Match 3
                      game. Prepare to embark on a journey filled with fun,
                      rewards, and endless entertainment.
                    </p>
                    <div className="btns-group mt-5">
                      <a
                        href="https://drive.google.com/file/d/1MlbzobUpj741tSDuzDNnEdjcrPWsa8h0/view?usp=drive_link"
                        className="btn btn-primary"
                      >
                        Download Game
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

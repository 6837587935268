import {
  WagmiConfig,
  createClient,
  defaultChains,
  configureChains,
  chain,
} from "wagmi";

import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";

import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

//alchemy api
const alApi = "3Z6PHGnPq37nJ1GJA-gCQp74pQ5yC5gQ";
//TESTNET api alchemy
// const alGoerliApi = "8OotlXzGFk2Htd1dXUTAIhB3vgzgtl96";
// Configure chains & providers with the Alchemy provider.
// Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
const { chains, provider, webSocketProvider } = configureChains(
  [chain.mainnet],
  [
    // alchemyProvider({ apiKey: alApi, priority: 1 }),
    // publicProvider({ priority: 2 }),
    jsonRpcProvider({
      priority: 0,
      rpc: (chain) => {
        if (chain.id === 1) {
          return {
            http: "https://eth-mainnet.nodereal.io/v1/d834d84fa3df4eff8d64fda0a93c80d2",
            webSocket:
              "wss://eth-mainnet.nodereal.io/ws/v1/d834d84fa3df4eff8d64fda0a93c80d2",
          };
        }
      },
    }),
  ]
);

// Set up client
const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),

    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
      },
    }),
  ],
  provider,
  webSocketProvider,
});

export default client;

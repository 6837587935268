export const handleWeb3Err = (err) => {
  if (err?.code === 32603) {
    return "your balance is not enough";
  }
  if (err?.reason) {
    return err.reason;
  }
  if (err?.data) {
    return err?.data?.message;
  }
};

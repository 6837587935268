import React from 'react'

import CardInvite from 'components/Layouts/Cards/CardInvite'
import invite_img_1 from 'assets/images/referral/broken-link.png'
import invite_img_2 from 'assets/images/referral/promotion.png'
import invite_img_3 from 'assets/images/referral/bitcoin2.png'

const invieData = [
    { id: 1, title: 'Get a referral link', imgUrl: invite_img_1, desc: 'Connect a wallet and generate your referral link in the Referral section.' },
    { id: 2, title: 'Invite friends', imgUrl: invite_img_2, desc: 'Invite your friends to register via your referral link' },
    { id: 3, title: 'Earn crypto', imgUrl: invite_img_3, desc: 'Receive referral rewards in BSW tokens from your friends earnings & swaps' },
]

const HowInvite = () => {
    return (
        <>
            <div className="how-invite-section">
                <div className="container">
                    <div className="page-title">
                        <div className="title-inner">
                            <h2>How to invite friends</h2>
                        </div>
                    </div>
                    <div className="section-inner">
                        <div className="row">
                            {
                                invieData.map((item, i) => (
                                    <div className="col-lg-4 col-md-6" key={item.id}>
                                        <CardInvite
                                            title={item.title}
                                            desc={item.desc}
                                            number={i + 1}
                                            imgUrl={item.imgUrl}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HowInvite
import React from "react";

import Logo from "assets/images/logo-simple.svg";

const WalletStatus = ({ setshowBuycoinTab }) => {
  return (
    <div className="form-content">
      <button
        type="button"
        className="btn-back"
        onClick={() => setshowBuycoinTab("wallet-buy")}
      >
        <i className="fi fi-rr-arrow-left"></i>Back
      </button>
      <div className="progressing">
        <div className="in-progress">
          <div className="thumb">
            <img loading="lazy" src={Logo} alt="" />
          </div>
          <div className="info">
            <h5>Your transaction is in progres</h5>
            <p>5000 PMCcoin will be avalaible for you to claim and...</p>
          </div>
        </div>
        {/* <div className="in-progress">
                    <div className="thumb actives">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                            <g fill="none" fill-rule="evenodd">
                                <circle className="svg-elem-1" cx="20" cy="20" r="18" stroke="#15d34e" stroke-width="2"></circle>
                                <circle className="svg-elem-2" cx="20" cy="20" r="15" fill="#f8fbff"></circle>
                                <polyline className="svg-elem-3" stroke="#fff" stroke-linecap="round" stroke-width="3" points="12 21 17.5 26.5 29 16"></polyline>
                            </g>
                        </svg>
                    </div>
                    <div className="info">
                        <h5>successfully</h5>
                    </div>
                    <div className="buttons-group mb-0 mt-4">
                        <button type='button' className='btn btn-primary'>Back To Home</button>
                    </div>
                </div> */}
      </div>
    </div>
  );
};

export default WalletStatus;

import Tab from "./Tab";

export default function Tabs({ data, showTab, setShowTab, align }) {
    return (
        <nav>
            <div
                className={`nav nav-pills justify-content-${align}`}
                role="tablist"
            >
                {data.map(tabinfo => (
                    <Tab
                        key={tabinfo.id}
                        title={tabinfo.title}
                        tabName={tabinfo.tabName}
                        showTab={showTab}
                        setShowTab={setShowTab}
                    />
                ))}
            </div>
        </nav>
    )
}

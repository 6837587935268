import React from "react";
import { Link } from "react-router-dom";

const CardGame = ({ name, genre, cover, handleCurrentPage }) => {
  return (
    <div className="card-game" onClick={handleCurrentPage}>
      <div className="card-inner">
        <div className="thumb">
          <img loading="lazy" src={cover} alt={name} />
        </div>
        <div className="info">
          <h3 className="name">{name}</h3>
          <p className="genre">
            Genre : <span>{genre + ', '}</span>
          </p>
        </div>
        <div className="backdrop-image">
          <div
            className="img-inner"
            style={{ backgroundImage: `url(${cover})` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default CardGame;

import { preSaleAbiFunctionsName } from "data/abiFunctions";
import { presaleContractAddress } from "data/consts";
import React, { useEffect, useState } from "react";
import { useContractRead } from "wagmi";
import preSaleAbi from "data/preSaleAbi.json";
import { ethers } from "ethers";

const ProgressBar = ({ title1, title2, percentage, min, max }) => {
  const [usdtPricePmc, setUsdtPricePMC] = useState(0);

  const priceData = useContractRead({
    address: presaleContractAddress,
    abi: preSaleAbi,
    functionName: preSaleAbiFunctionsName.salePrice,
  });
  useEffect(() => {
    if (priceData?.data) {
      setUsdtPricePMC(1 / ethers.utils.formatEther(priceData?.data || 0) || 0);
    }
  }, [priceData]);
  return (
    <div className="progress-group">
      <label htmlFor="" style={{ fontSize: "20px" }}>
        1 USDT = {usdtPricePmc} PMC
      </label>

      <div className="progress">
        <div
          className="progress-bar progress-bar-striped progress-bar-animated"
          aria-valuenow={percentage}
          aria-valuemin={min}
          aria-valuemax={max}
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
    </div>
  );
};

export { ProgressBar };

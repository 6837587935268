import React from "react";
import Headers from "components/Layouts/Header";
import Footer from "components/Layouts/Footer";
import BuyCoinHero from "components/Pages/BuyCoin/BuyCoinHero";
import HowBuyCoin from "./HowBuyCoin";
import { WagmiConfig } from "wagmi";
import client from "./connection";

const BuyCoin = () => {
  return (
    <>
      <Headers />
      <WagmiConfig client={client}>
        <div className="wrapper-buycoin">
          <BuyCoinHero />
          <HowBuyCoin />
          {/* <ContactUs /> */}
        </div>
      </WagmiConfig>
      <Footer />
    </>
  );
};

export default BuyCoin;

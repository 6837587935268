
export default function Tab({ title, tabName, showTab, setShowTab }) {
    return (
        <button
            className={`nav-link ${showTab === `${tabName}` ? "active" : ""
                }`}
            type="button"
            role="tab"
            onClick={() => setShowTab(`${tabName}`)}
        >
            <span>{title}</span>
        </button>
    )
}

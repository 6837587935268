import React from "react";
import RoutesList from "Routes";
import { ParallaxProvider } from "react-scroll-parallax";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { GOOGLE_ANALYTICS_TRACKING_ID } from "utils/env";
import { useEffect } from "react";

function App() {
  const TRACKING_ID = GOOGLE_ANALYTICS_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    console.log("Google Analytics Initialized");
    console.log("Tracking ID: ", TRACKING_ID);
  }, [window.location.pathname]);

  return (
    <ParallaxProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <meta
          name="description"
          content="Planet Memes Studio is building games that merges the best of web2 and web3 gaming. Our games are built with a sustainable economy solution that facilitates this merge."
        />
        <meta
          name="keywords"
          content="Web2, Web3, Gaming, Sustainable economy, Cryptocurrency, NFTs, Game development, Decentralized finance, Play-to-earn, Digital assets, Metaverse, Online gaming, Game economy, Crypto games, Game design, Gaming industry, In-game assets,
          Tokenomics,memes,dogecoin,shiba,meme coins, memes wallet, memes academy, floki, planet memes, planet memes studio"
        />
        <meta name="author" content="Planetmemes" />
        <meta name="theme-color" content="#191923" />
        <meta name="msapplication-navbutton-color" content="#191923" />
        <meta name="apple-mobile-web-app-status-bar-style" content="#191923" />
        <title>Planet Memes Studio</title>
      </Helmet>
      <RoutesList />
    </ParallaxProvider>
  );
}

export default App;

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "components/Pages/Home";
import TermOfService from "components/Pages/Terms";
import Privacy from "components/Pages/Privacy";
import Referral from "components/Pages/Referral";
import BuyCoin from "components/Pages/BuyCoin";
import ReferralLanding from "components/Pages/ReferralLanding";
import GamesList from "components/Pages/GamesList";
import GameDetails from "components/Pages/GameDetails";
import Portfolio from "components/Pages/Portfolio";

const RoutesList = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/term_of_service" element={<TermOfService />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/referral" element={<Referral />} />
          <Route path="/buy_coin" element={<BuyCoin />} />
          <Route path="/referral_landing" element={<ReferralLanding />} />
          <Route path="/games_list" element={<GamesList />} />
          <Route path="/game_details/:id" element={<GameDetails />} />
          <Route path="/user_portfolio" element={<Portfolio />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default RoutesList;

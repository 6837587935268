import React from "react";

const TextDescription = () => {
  return (
    <div className="text-description-section">
      <div className="container">
        <div className="section-inner">
          <h2>Privacy And Policy</h2>
          <h2> 1. Principles</h2>
          <p>
            Our application is based on principles relating to the processing of
            Personal Data, and we commit to the following principles relating to
            the processing of Personal Data: · we process your Personal Data
            lawfully, fairly, and in a transparent manner by providing this
            Privacy Policy and showing legal bases for the processing of your
            Personal Data. · we collect the Personal Data for specified,
            explicit, and legitimate purposes mentioned in this Privacy Policy.
            · we process Personal Data that is adequate, relevant, and limited
            to what is necessary in relation to the purposes for which it is
            processed, especially since we don't require you to register or log
            in.We don't have access to your funds (private keys).We don't
            require your name or email address. · we collect accurate and, where
            necessary, kept up to date Personal Data when applicable and
            relevant; · we store your Personal Data for no longer than is
            necessary for the purposes for which the Personal Data are
            processed; · we use and ensure appropriate security of the Personal
            Data
          </p>
          <h2>2. Registration/Login Not Required</h2>
          <p>Memes Wallet does not require you to register or log in.</p>
          <h2>3. Private Keys Stay On Device</h2>
          <p>Your private keys will stay on your device.</p>

          <h2>4. Categories Of Collected Personal Data</h2>
          <p>
            We collect the following categories of your Personal Data: · Device
            Information :We may collect information about the device you use to
            access our Application, including the hardware model, operating
            system and version, unique device identifiers, and mobile network
            information. This information will never be communicated to third
            parties unless you provide prior specific consent. · Analytical
            Information : that includes information about how you use the
            Application. · Information you may provide to us : virtual currency
            addresses and any other information you choose to provide will never
            be communicated to third parties unless you provide prior specific
            consent.
          </p>

          <h2>5. Processed Personal Data</h2>
          <p>
            We process personal data when you interact with the Application or
            use our services, especially when: · you use our services · you
            browse any page or section of the Application · you communicate with
            us or leave a query · you leave a comment on the Website · you
            receive notification from us · we need to share your personal data
            with authorized third parties to provide the services · we measure
            or analyze the Application's traffic
          </p>

          <h2>
            6. Purposes And Legal Bases For The Processing Of Your Personal Data
          </h2>
          <p>
            We process your Personal Data for the following purposes: ·
            Providing services. We need to provide services via the Application.
            Legal basis: contract. · Performing transactions. Legal basis:
            contract. · Providing offers/updates which may be interesting to
            you. Legal basis: legitimate interests for offers and updates. ·
            Compliance with applicable regulatory rules. Legal basis: legal
            obligation. · Keeping the Application running (managing your
            requests, remembering your settings, hosting, and back-end
            infrastructure). Legal basis: legitimate interests. · Improving the
            Application (testing features, interacting with feedback platforms,
            managing landing pages, heat mapping the Application, traffic
            optimization, and data analysis and research, including profiling
            and the use of machine learning and other techniques over your
            Personal Data and in some cases using third parties to do this).
            Legal basis: legitimate interests. · Customer support (notifying you
            of any changes to the Application, services, solving issues, any bug
            fixing). Legal basis: legitimate interests.
          </p>

          <h2>7. Blockchain Transactions</h2>
          <p>
            Your blockchain transactions may be relayed through servers or nodes
            and will be publicly visible due to the public nature of distributed
            ledger systems.
          </p>

          <h2>
            8. Secure Communication With Our Servers, Confidentiality, And
            Security Measures
          </h2>
          <p>
            We have security and organizational measures and procedures to
            secure the data collected and stored and to prevent it from being
            accidentally lost, used, altered, disclosed, or accessed without
            authorization. All of our servers support HTTPS. We allow disclosure
            of your personal data only to those employees and companies who have
            a business need to know such data. They will process the Personal
            Data on our instructions, and they are obliged to do it
            confidentially under confidentiality agreements. You acknowledge
            that no data transmission is guaranteed to be 100% secure, and there
            may be risks. You are responsible for your login information and
            private keys. You shall keep them confidential.
          </p>

          <h2>9. Communication With Third-Party Servers</h2>
          <p>
            Exchange rates, balances, transactions, and other blockchain
            information may be read from or related to third-party servers. We
            cannot guarantee the privacy of your Internet connection.
          </p>

          <h2>10. Memes Wallet Usage Statistics</h2>
          <p>
            We may collect Memes Wallet services usage information in order to
            improve function or UI but will only use this information in an
            aggregated, anonymized fashion.
          </p>

          <h2>11. Support Requests</h2>
          <p>
            If you contact support, then as part of the support request, we may
            incidentally collect your personal data, but we will endeavor to
            keep that information secure and confidential.
          </p>

          <h2>12. Retention Policy</h2>
          <p>
            We store the Personal Data as long as we need it and the retention
            practice depends on the type of data we collect, regulatory burden,
            and how we use the Personal Data. The retention period is based on
            criteria that include legally mandated retention periods, pending or
            potential litigation, tax and accounting laws, intellectual property
            or ownership rights, contract requirements, operational directives
            or needs, and historical archiving. In some circumstances, we may
            anonymize your Personal Data for research or statistical purposes,
            in which case we may use this information indefinitely without
            further notice to you.
          </p>

          <h2>13. Changes To The Privacy Policy</h2>
          <p>
            Memes Wallet reserves the right to change this Privacy Policy at any
            time. Any changes will be posted so that you are always aware of
            what information we collect, how we use it, and under what
            circumstances we disclose it. We will inform you about the changes
            when it is possible.
          </p>
        </div>
        <div className="section-inner">
          <h2>How you accept this policy</h2>
          <p>
            By creating or importing a locally hosted wallet (a “Wallet”), or
            visiting our website, you acknowledge that you have read,
            understood, and agreed to these Terms, as well as the accompanying
            [Privacy Policy] We reserve the right to change these Terms at any
            time. Any such changes with respect to your use of Memes Wallet will
            take effect immediately when posted on our website or on our mobile
            application. Your continued use of Memes Wallet following any such
            change will signify your acceptance to be bound by the then current
            Terms. Please check the effective date above to determine if there
            have been any changes since you have last reviewed these Terms. If
            you do not agree to this Agreement or any modifications to this
            Agreement, you should not use Memes Wallet. Failure or delay by
            Memes Wallet in enforcing or partially enforcing any provision of
            these Terms shall not be construed as a waiver of any rights.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TextDescription;

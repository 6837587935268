import React from 'react'

const ModalAlert = ({ title, icon, status, showModal, setShowModal, children, buttonAcceptName }) => {
    return (
        <>
            <div className={`modal modal-alert fade ${showModal ? 'show' : ''}`} tabIndex="-1"

            >
                <div className={`modal-backdrop fade ${showModal ? 'show' : ''}`} onClick={() => setShowModal(false)}></div>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
                        <div className={`modal-status ${status}`}>
                            <div className="icon">
                                <i className={`fi ${icon}`}></i>
                            </div>
                            <h4>{title}</h4>
                        </div>
                        <div className="modal-body">
                            {children}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => setShowModal(false)}>
                                {buttonAcceptName}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModalAlert
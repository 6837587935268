import React from "react";
import { Link } from "react-router-dom";

// import NavLinks from "../Header/NavLinks";
import { Button } from "components/Common";
import FooterLink from "./FooterLink";

import logo from "assets/images/logo-2.png";
import shft_logo from "assets/images/shft-logo.png";

const socialData = [
  { id: 1, icon: "fi-brands-discord", url: "https://discord.gg/s4rFThfkXG" },
  {
    id: 2,
    title: "youtube",
    icon: "fi-brands-youtube",
    url: "https://www.youtube.com/channel/UCuGGp_yj6jjsWC6t914_1cQ",
  },
  {
    id: 3,
    title: "twitter",
    icon: "fi-brands-twitter",
    url: "https://twitter.com/PlanetMemes4",
  },
  {
    id: 4,
    title: "telegram",
    icon: "fi-brands-telegram",
    url: "https://t.me/PlanetMemesOf",
  },
];

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="footer-inner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="info">
                  <div className="logo">
                    <Link to="/">
                      <img loading="lazy" src={logo} alt="" />
                    </Link>
                  </div>
                  <p>Buy, exchange, and own meme coins on Planet Memes.</p>
                  {/* <div className="incubated">
                    <h6>
                      Incubated by :{" "}
                      <a href="https://shft.shorooq.com/" target="_blank">
                        <img src={shft_logo} alt="shft-logo" />
                      </a>
                    </h6>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="menu-links">
                  <FooterLink />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="social-icons">
                  <ul>
                    {socialData.map((item) => (
                      <li key={item.id} className={item.title}>
                        <a href={item.url}>
                          <i className={`fi ${item.icon}`}></i>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bg"></div>
        <div className="copyright">
          <h6>
            Copyright 2022 <span>Planet Memes</span>
          </h6>
        </div>
      </footer>
    </>
  );
};

export default Footer;

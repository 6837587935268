import imgBuyCoin_step_2_1 from '../assets/images/buycoin/buycoin-step-2-1.png'
import imgBuyCoin_step_2_2 from '../assets/images/buycoin/buycoin-step-2-2.png'
import imgBuyCoin_step_2_3 from '../assets/images/buycoin/buycoin-step-2-3.png'

const BuyCoinCardData = [
    {
        id: 1,
        title: 'BUY ETH WITH CARD',
        imgUrl: imgBuyCoin_step_2_3,
        desc: 'You can use Ramp Network in order to purchase ETH using your card, which you can then swap for PMC.\nWe recommend buying at least $30 of Eth in order to ensure you have enough to buy PMC and cover the gas fees.'
    },
    {
        id: 2,
        title: 'BUY PMC WITH ETH',
        imgUrl: imgBuyCoin_step_2_1,
        desc: 'If you have enough ETH in your wallet, you can use the purchase PMC with ETH option in order to swap the ETH in your wallet for PMC.\nType in the amount of PMC you wish to purchase and then click “Convert ETH”. Your wallet provider will ask you to confirm the transaction and will also show you the cost of gas.'
    },
    {
        id: 3,
        title: 'BUY PMC WITH USDT',
        imgUrl: imgBuyCoin_step_2_2,
        desc: 'If you have USDT in your wallet, you can use the “Purchase PMC with USDT” option in order to swap the USDT in your wallet for PMC.\nType in the amount of PMC you wish to purchase and then click “Convert USDT”. Your wallet provider will ask you to authorise PMC to access the USDT, then will ask you to confirm the transaction and will also show you the cost of gas.'
    },
]
export default BuyCoinCardData
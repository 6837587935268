import { totalTokens } from "data/consts";
import React from "react";

export const CoinInput = ({
  title,
  coinIcon,
  coinName,
  showMsg,
  msgStatus,
  msgText,
  inputReadOnly,
  onChange,
  value,
}) => {
  return (
    <div className="coin-inputgroup mb-3">
      <label>{title}</label>
      <div className="coin-icon">
        <div className="thumb">
          <img loading="lazy" src={coinIcon} alt="ETH-icon" />
        </div>
        <h6>{coinName}</h6>
      </div>
      <input
        type="number"
        className="input"
        placeholder="0.0"
        max={totalTokens}
        value={value}
        onChange={onChange}
        readOnly={inputReadOnly && true}
      />
      {showMsg ? (
        <div className={`message-status ${msgStatus}`}>
          <span>{msgText}</span>
        </div>
      ) : null}
    </div>
  );
};

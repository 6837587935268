export const GamesInformation = [
  {
    id: 1,
    name: "Clash of Memes",
    cover: "/static/images/games/clash-of-memes/cover.jpg",
    screenShots: [
      "/static/images/games/clash-of-memes/sc-1.jpg",
      "/static/images/games/clash-of-memes/sc-2.jpg",
    ],
    desc: "Clash of Memes is a real-time strategy game inspired by Clash Royale. This game delivers AAA-quality graphics and cutting-edge features and allows players to collect and upgrade cards featuring meme-themed characters and abilities, as well as spells and towers to defend their base",
    downloads: "15k download",
    developer: "Planet memes studio",
    rating: "4.9",
    network: "Pmc",
    release: "Official",
    // tags: ["Action", "RPG", "TD"],
    platforms: ["mobile"],
    genre: ["RTS", "Casual"],
  },
  {
    id: 2,
    name: "Meme Coin Mania",
    cover: "/static/images/games/meme-coin-mania/cover.jpg",
    screenShots: ["/static/images/games/meme-coin-mania/sc-1.jpg"],
    desc: "Meme Coin Mania is a match-3 game where players select their favorite meme coin character as an NFT and set out to collect the necessary ship parts and resources by playing through the challenging levels of the game and enjoying rewards.",
    downloads: "15k download",
    developer: "Planet memes studio",
    rating: "4.9",
    network: "Pmc",
    release: "Official",
    // tags: ["Platformer"],
    platforms: ["mobile"],
    genre: ["Puzzle", "Casual"],
  },
];

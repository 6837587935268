import React, { useEffect } from 'react'
import AOS from 'aos'
import "aos/dist/aos.css";

import memeNft_img from 'assets/images/memenft.png'

export default function MemeNft() {

    useEffect(() => {
        AOS.init();
        AOS.refresh()
    }, [])

    return (
        <>
            <div className="description-section pt-3 pb-2">
                <div className="container">
                    <div className="section-inner">
                        <div className="row align-items-center">
                            <div className="col-lg-6 order-lg-1 order-md-2 order-2">
                                <div className="image-content width-100" data-aos="fade-right">
                                    <div className="img-inner">
                                        <img src={memeNft_img} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-lg-2 order-md-1 order-1">
                                <div className="text-content" data-aos="fade-up">
                                    <h2>
                                        <span>MemeNFT </span>  Reserve
                                    </h2>
                                    <div className="desc">
                                        <p>
                                            MemeNFT Reserve Model, a first-of-its-kind innovation that brings real utility to meme coins and NFTs. With this model, NFTs in Clash of Memes, Meme Coin Mania, and Pepe Match become deflationary and hold 50% of their value in meme coins. It's a game-changer that adds value, scarcity, and unique benefits to your NFTs.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

import userThumb from 'assets/images/bg/user-nft.jpg'
export const PortfolioHero = () => {
    return (
        <>
            <div className="portfolio-section">
                <div className="container">
                    <div className="section-inner">
                        <div className="user-image">
                            <div className="img-inner">
                                <img src={userThumb} alt="user-thumb" />
                            </div>
                            <div className="name">
                                <h2>Xangel619 <span className="icon"><i className="fi fi-rr-check"></i></span></h2>
                            </div>
                        </div>
                        <div className="social-icons">
                            <ul>
                                <li><a href="#"><i className="fi fi-rr-globe"></i></a></li>
                                <li><a href="#"><i className="fi fi-brands-twitter"></i></a></li>
                                <li><a href="#"><i className="fi fi-brands-discord"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

import React from "react";
import Headers from "components/Layouts/Header";
import HomeHero from "components/Layouts/Heros/HomeHero";
import SectionDescription from "components/Layouts/SectionDescription";
import AppScreenSlider from "components/Layouts/AppScreenSlider";
import Partner from "./Partner";
import Backers from "./Backers";
import Footer from "components/Layouts/Footer";

import MarketPlace from "./MarketPlace";
import DevelopersStudio from "./DevelopersStudio";
import AboutMarketplace from "./AboutMarketplace";
import Games from "./Games";
import MemeNft from "./MemeNft";
import Tokenomics from "./Tokenomics";
import Included from "./Included";

import Token from "./Token";

import PepeMatch from "./PepeMatch";
import Teams from "components/Layouts/Team";
import Advisor from "./Advisor";
import SwapWidget from "./SwapWidget";


const Home = () => {
  return (
    <>
      <Headers />
      <HomeHero />
      <SectionDescription />
      <MemeNft />
      <AppScreenSlider />
      <PepeMatch />
      <Games />
      <MarketPlace />
      <AboutMarketplace />
      {/* <Partner /> */}
      {/* <Backers /> */}
      {/* <Included /> */}
      {/* <Tokenomics /> */}
      {/* <DevelopersStudio /> */}
      {/* <Planet /> */}
      {/* <Ecosystem /> */}
      {/* <ClashOfMemes /> */}
      {/* <DownloadApp /> */}
      {/* <Roadmap /> */}
      {/* <SuperAppSlider /> */}
  {/*  <SwapWidget /> */}
      <Token />
      <Footer />
    </>
  );
};

export default Home;

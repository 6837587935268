import { presaleContractAddress } from "data/consts";
import { useContractRead } from "wagmi";
import preSaleAbi from "data/preSaleAbi.json";
import { preSaleAbiFunctionsName } from "data/abiFunctions";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import Countdown from "react-countdown";

const WalletRemaining = ({ setshowBuycoinTab }) => {
  const { data, isLoading, isError } = useContractRead({
    address: presaleContractAddress,
    abi: preSaleAbi,
    functionName: preSaleAbiFunctionsName.inSale,
  });

  return (
    <div className="form-content">
      <div className="coin-remaining">
        {/* <h6>Remaining Tokens: </h6>
        <h4> {data?.toNumber()}</h4>
        <h6>PMC</h6>
        <p>Unit 1 USDT = {usdtPricePmc} PMC</p> */}
        <div className="progress-group">
          <label htmlFor="">Time Remaining: </label>
          <label htmlFor="">
            {<Countdown className="countdown" date={1666654533000} />}
          </label>
        </div>
      </div>
      <h6 className="title text-center mb-4">
        Please press "Connect Wallet" to Buy
      </h6>
      <div className="buttons-group">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setshowBuycoinTab("wallet-choose")}
        >
          Connect Wallet
        </button>
      </div>
    </div>
  );
};

export default WalletRemaining;

import React from "react";

import imgBuyCoin_step_1 from "assets/images/buycoin/buycoin-step-1.png";
import BuyCoinCardData from "utils/BuyCoinCardData";
import CardBuyCoin from "components/Layouts/Cards/CardBuyCoin";

const howBuyCoin = () => {
  return (
    <>
      <div className="how-buyCoin" id="how-to-buy">
        <div className="container">
          <div className="page-title mb-5">
            <div className="title-inner">
              <h2>How to Buy ?</h2>
            </div>
          </div>
          <div className="section-inner">
            {/* Step 1 */}
            <div className="step-inner">
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <div className="text-content">
                    <h2>Step 1</h2>
                    <div className="desc">
                      <p>
                        Make sure you have a MetaMask wallet installed on your
                        browser, or use one of the wallets supported by Wallet
                        Connect.
                      </p>
                      <p>
                        Purchasing on a desktop browser will give you a smoother
                        purchasing experience. For this we recommend Metamask.
                      </p>
                      <p>
                        If you are purchasing on mobile, we recommend using
                        Trust Wallet and connecting through the in built
                        browser.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="image-content align-right">
                    <div className="img-inner">
                      <img loading="lazy" src={imgBuyCoin_step_1} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Step 2 */}
            <div className="step-inner align-center">
              <div className="text-content">
                <h2>Step 2</h2>
                <div className="desc">
                  <p>
                    Once you’ve connected your wallet, you’ll be presented with
                    3 options with which you can purchase PMG Token.
                  </p>
                  <p>
                    Read below for more informaiton on these three options, and
                    select the option which is right for you.
                  </p>
                </div>
              </div>
              <div className="row justify-content-center mt-5">
                {BuyCoinCardData?.map((cardData) => (
                  <div className="col-lg-4 col-md-6" key={cardData.id}>
                    <CardBuyCoin
                      title={cardData.title}
                      imgUrl={cardData.imgUrl}
                      desc={cardData.desc}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default howBuyCoin;

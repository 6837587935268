import React from 'react'
import { Link } from 'react-router-dom'
import { linksData } from '../Header/NavData'

const FooterLink = () => {
    return (
        <>
            <ul>
                {
                    linksData?.map(link => (
                        <li key={link.id}>
                            <a href={link.url}>{link.title}</a>
                        </li>
                    ))

                }
                <li>
                    <Link to="/privacy" rel="noreferrer">
                        Privacy
                    </Link>
                </li>
                <li>
                    <Link to="/term_of_service" rel="noreferrer">
                        Terms
                    </Link>
                </li>
            </ul>
        </>
    )
}

export default FooterLink
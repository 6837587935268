import React from "react";

const GameDetailsHero = ({ heroImageUrl }) => {
  return (
    <>
      <div
        className="hero-bg-image"
        style={{ backgroundImage: `url(${heroImageUrl})` }}
      >
        <div className="container-lg"></div>
      </div>
    </>
  );
};

export default GameDetailsHero;

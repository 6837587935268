import React from "react";

const ImageContent = ({ src, alt }) => {
  return (
    <div className="image-content">
      <div className="img-inner">
        <img loading="lazy" src={src} alt={alt} />
      </div>
    </div>
  );
};

export default ImageContent;
